import React, { useEffect } from "react";
import {
  createSearchParams,
  Link,
  Link as RouterLink,
  useNavigate,
} from "react-router-dom";
import { DeviceLocationMap } from "./device-location-map";
import { DeviceLastSeenBadge } from "../device-last-seen-badge";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../../app/store";
import { fetchDevice, selectDeviceById } from "../devices-slice";
import { Cog6ToothIcon } from "@heroicons/react/24/outline";
import { Card } from "../../../components/containers/card";
import { PageHeadline } from "../../../components/text/page-headline";
import { selectTagsOnDeviceByDeviceId } from "../../tags/tag-on-device-slice";
import { selectTagsByIds } from "../../tags/tags-slice";
import { TagBadge } from "../../tags/tagBadge";
import { Can } from "../../../auth/user-ability-provider";
import { FindMeButton } from "./find-me-button";
import { createAnalyticsParamsWithDefaultProperties } from "../../analytics/analytics-dashboard-page";
import { AnalyticsIcon } from "../../../assets/icons";
import { Button, ButtonGroup } from "../../../components/buttons/button-group";
import { NotificationCard } from "../../../components/containers/notification-card";
import { deviceKey } from "../pair/pair-device-page";
import { PageWidth } from "../../../components/containers/page-width";
import { FlexContainer } from "../../../components/containers/flex-container";
import { DeviceEvents } from "./device-events";
import { DeviceHistoricData } from "./device-historic-data";
import { DeviceStatusData } from "./device-status-data";
import { DeviceIcon } from "../device-icon";
import { AnalyticsDataLoaderContextProvider } from "../../analytics/analytics-data-loader-context";
import { DeviceCounters } from "./device-counters";

export function DeviceDetailPage({ deviceId }: { deviceId: string }) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const device = useAppSelector((state) => selectDeviceById(state, deviceId));

  const tagsOnDevices = useAppSelector((state) =>
    selectTagsOnDeviceByDeviceId(state, deviceId)
  );
  const tagIdsOnDevice = tagsOnDevices.map((t) => t.tag_id);
  const tags = useAppSelector((state) =>
    selectTagsByIds(state, tagIdsOnDevice)
  );

  useEffect(() => {
    if (deviceId && !device) {
      // Fetch the device and see if the device is present within the account
      dispatch(fetchDevice(deviceId)).then((res) => {
        const status = res.meta.requestStatus;
        const payload = res.payload;
        if (status === "fulfilled" && !payload) {
          // If the device cannot be found we redirect the use to the pairing page with the id
          navigate({
            pathname: "/devices/pair",
            search: "?" + createSearchParams([[deviceKey, deviceId]]),
          });
        }
      });
    }
  }, [navigate, dispatch, deviceId]);

  const deviceAnalyticsParams = createAnalyticsParamsWithDefaultProperties(
    deviceId,
    "device"
  );

  return (
    <AnalyticsDataLoaderContextProvider
      loadComparisonData={true}
      properties={["ea_fwd_t_d", "ea_fwd_t_d_cost", "er_t_d"]}
      entities={[
        {
          scope: "device",
          id: deviceId,
        },
      ]}
    >
      <PageWidth>
        <FlexContainer>
          <div className="flex flex-col flex-wrap justify-between gap-2 md:flex-row">
            <PageHeadline>
              <DeviceIcon type={device?.type} />
              {device?.name
                ? `${device?.name} (${device.id})`
                : t("device.detail.deviceNoName", { deviceId })}
              <DeviceLastSeenBadge deviceId={deviceId} />
            </PageHeadline>

            <ButtonGroup>
              <FindMeButton deviceId={deviceId} />
              <Button>
                <RouterLink
                  key="goToSettings"
                  to={`/analytics/${deviceAnalyticsParams}`}
                >
                  <div
                    className={"flex justify-items-center gap-1"}
                    title={t(
                      "device.detail.analyticsTitle",
                      "go to analytics for the device"
                    )}
                  >
                    <AnalyticsIcon className="h-5 w-5" />
                    {t("device.detail.analytics", "analytics")}
                  </div>
                </RouterLink>
              </Button>
              <Button>
                <RouterLink key="goToSettings" to={"settings"}>
                  <div className={"flex justify-items-center gap-1"}>
                    <Cog6ToothIcon className="h-5 w-5" />
                    {t("device.settings.title", "settings")}
                  </div>
                </RouterLink>
              </Button>
            </ButtonGroup>
          </div>

          {device?.deleted_at != null && (
            <NotificationCard variant={"error"}>
              <div className="flex flex-row items-center justify-between gap-2">
                <span>
                  {t(
                    "device.detail.deviceNotPaired",
                    "This device is not paired with the account anymore. You can pair it again."
                  )}
                </span>
                <Link to={`/devices/pair?${deviceKey}=${deviceId}`}>
                  <button
                    className="inline-flex justify-center rounded-md border border-transparent bg-blue py-2 px-4 text-sm text-white shadow-sm focus:outline-none"
                    type="button"
                  >
                    {t("device.detail.pairDevice", "Pair device")}
                  </button>
                </Link>
              </div>
            </NotificationCard>
          )}

          <Can I="use" a="historicalData">
            <DeviceHistoricData deviceId={deviceId} />
          </Can>

          <Can I="use" a="historicalData">
            {device && !device.tariff_id && (
              <NotificationCard
                variant="info"
                className="bg-white"
                noBackground={true}
              >
                {t(
                  "device.detail.noTariffWarning",
                  "The device is not assigned to an energy tariff and therefore we cannot calculate energy costs."
                )}
              </NotificationCard>
            )}
          </Can>

          <Can I="use" a="events">
            {!!device && <DeviceEvents deviceId={deviceId} />}
          </Can>

          <DeviceStatusData deviceId={deviceId} />

          <DeviceCounters deviceId={deviceId} />

          <Card>
            <h2 className="mb-4 text-lg">
              {t("device.detail.tags", "Groups")}
            </h2>
            <div className="mb-4 flex">
              {tags &&
                tags.map((tag) => (
                  <Link key={tag.id} to={`/tags/${tag.id}`}>
                    <TagBadge tag={tag} />
                  </Link>
                ))}
            </div>
          </Card>

          <div className={"card rounded-lg"}>
            <DeviceLocationMap deviceId={deviceId} />
          </div>
        </FlexContainer>
      </PageWidth>
    </AnalyticsDataLoaderContextProvider>
  );
}
