import React, { ChangeEvent } from "react";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../../../app/store";
import {
  fetchDeviceRulesLoadingState,
  selectDeviceRulesByDeviceId,
  setDeviceRules,
  setDeviceRulesLoadingState,
  DeviceRuleAdded,
  deviceRuleRemove,
  deviceRuleUpdate,
} from "../../devices-slice";
import { PlusIcon, TrashIcon } from "@heroicons/react/24/solid";
import { ElectricMetricKey, getMetricHelper } from "@nantis/gridknight-core";
import { Application } from "@nantis/gridknight-core";
import cuid from "cuid";
import { LoadingButton } from "../../../../components/ng";

export const availableProperties: ElectricMetricKey[] = [
  "tplug",
  "urms_1",
  "urms_2",
  "urms_3",
  "irms_1",
  "irms_2",
  "irms_3",
  "irms_n",
  "ic",
  "p_t",
];
export const availableComparators: Application.ValueThresholdComparator[] = [
  "gt",
  "lt",
];

function DeviceRulesSettings({
  threshold,
  onChange,
  onRemove,
}: {
  threshold: Application.DeviceRule<"dt">;
  onChange: (threshold: Application.DeviceRule<"dt">) => void;
  onRemove: (threshold: Application.DeviceRule<"dt">) => void;
}) {
  const { t } = useTranslation();

  const onChangeProperty = (e: ChangeEvent<HTMLSelectElement>) => {
    const newProperty = e.target.value as ElectricMetricKey;
    const mh = getMetricHelper(newProperty);

    onChange({
      ...threshold,
      data: {
        ...threshold.data,
        prop: e.target.value,
        lim: mh.range.nominal,
      },
    });
  };
  const onChangeComparator = (e: ChangeEvent<HTMLSelectElement>) => {
    onChange({
      ...threshold,
      data: {
        ...threshold.data,
        comp: e.target.value as Application.ValueThresholdComparator,
      },
    });
  };

  const onChangeLimit = (e: ChangeEvent<HTMLInputElement>) => {
    onChange({
      ...threshold,
      data: {
        ...threshold.data,
        lim: parseFloat(e.target.value),
      },
    });
  };

  const onChangeDeadtime = (e: ChangeEvent<HTMLInputElement>) => {
    onChange({
      ...threshold,
      data: {
        ...threshold.data,
        dt: parseInt(e.target.value),
      },
    });
  };

  const removeThreshold = () => {
    onRemove(threshold);
  };

  const mh = getMetricHelper(threshold.data.prop as ElectricMetricKey);

  const thresholdData = threshold.data;

  return (
    <div className="flex items-center justify-between rounded-lg border border-gray-200 p-2 text-gray-900 md:px-4">
      <div>
        <span>
          {t("device.events.settings.thresholdPrefix", "the value of")}
        </span>

        <div className="m-2 inline-block">
          <label htmlFor="location" className="sr-only text-sm text-gray-700">
            Location
          </label>
          <select
            onChange={onChangeProperty}
            value={thresholdData.prop.toLowerCase()}
            id="location"
            name="location"
            className="mt-1  block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-green focus:outline-none sm:text-sm"
          >
            {availableProperties.map((value) => {
              return (
                <option value={value} key={value}>
                  {t(`datum:metrics.${value.toLowerCase()}.label`, value)}
                </option>
              );
            })}

            {!availableProperties.find(
              (element) =>
                element.toLowerCase() === thresholdData.prop.toLowerCase()
            ) && (
              <option key={thresholdData.prop}>
                {t(
                  `datum:metrics.${thresholdData.prop.toLowerCase()}.label`,
                  thresholdData.prop
                )}
              </option>
            )}
          </select>
        </div>

        <div className="m-2 inline-block">
          <span>{t("device.events.settings.for", "for")}</span>
        </div>

        <div className="m-2 mr-2 inline-block w-36">
          <label htmlFor="deadtime" className="sr-only text-sm text-gray-700">
            {t("device.events.settings.deadtime", "deadtime")}
          </label>
          <input
            value={thresholdData.dt}
            onChange={onChangeDeadtime}
            type="number"
            id="deadtime"
            name="deadtime"
            className="mt-1 mr-2 block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-green focus:outline-none sm:text-sm"
          />
        </div>

        <span>{t("device.events.settings.seconds", "seconds")}</span>

        <div className="m-2 inline-block">
          <label htmlFor="comparator" className="sr-only text-sm text-gray-700">
            {t("device.events.settings.comparator", "comparator")}
          </label>
          <select
            onChange={onChangeComparator}
            value={thresholdData.comp}
            id="comparator"
            name="comparator"
            className="mt-1 block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-green focus:outline-none sm:text-sm"
          >
            {availableComparators.map((value) => {
              return (
                <option value={value} key={value}>
                  {t(`device.events.comparators.${value}`, value)}
                </option>
              );
            })}
          </select>
        </div>

        <div className="m-2 inline-block">
          <label htmlFor="limit" className="sr-only text-sm text-gray-700">
            Limit
          </label>
          <input
            value={thresholdData.lim}
            onChange={onChangeLimit}
            type="number"
            id="limit"
            name="limit"
            className="mt-1 block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-green focus:outline-none sm:text-sm"
          />
        </div>

        {mh.unit.name}

        <div className="m-2 inline-block">
          <span>{t("device.events.settings.is", "")}</span>
        </div>
      </div>
      <div
        title={t(
          "device.events.settings.removeThreshold",
          "remove the threshold"
        )}
        className="cursor-pointer align-middle"
      >
        <TrashIcon onClick={removeThreshold} className="h-5 w-5" />
      </div>
    </div>
  );
}

export function DeviceRuleSettings({
  device_id,
  maxRules = 5,
}: {
  device_id: string;
  maxRules?: number;
}) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const rules = useAppSelector((state) =>
    selectDeviceRulesByDeviceId(state, device_id)
  );
  const setDeviceRulesState = useAppSelector((state) =>
    setDeviceRulesLoadingState(state)
  );
  const fetchDeviceRulesState = useAppSelector((state) =>
    fetchDeviceRulesLoadingState(state)
  );

  const onAddRule = () => {
    const property = availableProperties[0];
    const mh = getMetricHelper(property);

    dispatch(
      DeviceRuleAdded({
        device_id: device_id,
        id: cuid(),
        type: "dt",
        data: {
          prop: property,
          comp: "gt",
          lim: mh.range.nominal,
          dt: 0,
        },
      } as Application.DeviceRule<"dt">)
    );
  };

  const onRemoveRule = (rule: Application.DeviceRule<any>): void => {
    dispatch(deviceRuleRemove(rule.id));
  };

  const onRuleChange = (rule: Application.DeviceRule<any>): void => {
    dispatch(deviceRuleUpdate(rule));
  };

  const onSaveRules = async () => {
    if (rules) {
      await dispatch(
        setDeviceRules({
          device_id: device_id,
          rules,
        })
      );
    }
  };

  if (fetchDeviceRulesState.status === "pending") {
    return (
      <div
        className={
          "flex h-full w-full items-center justify-center text-lg text-gray-700"
        }
      >
        {t("device.events.settings.loadingData", "Loading rules")}
      </div>
    );
  }

  return (
    <div>
      {fetchDeviceRulesState.status === "fulfilled" && (
        <div>
          <div>
            {rules?.length !== undefined && (
              <div className="">
                <p>
                  {t(
                    "device.events.settings.sendAlertWhen",
                    "send a notification when..."
                  )}
                </p>

                {rules.map((threshold) => {
                  return (
                    <div className="mt-5" key={threshold.id}>
                      <DeviceRulesSettings
                        onRemove={onRemoveRule}
                        onChange={onRuleChange}
                        threshold={threshold}
                      />
                    </div>
                  );
                })}
              </div>
            )}

            {rules?.length !== undefined && rules?.length < maxRules && (
              <button
                onClick={onAddRule}
                title={t(
                  "device.events.settings.addThreshold",
                  "add a new threshold"
                )}
                type="button"
                className="mt-5 inline-flex items-center rounded-full border border-transparent bg-blue p-2 text-white shadow-sm focus:outline-none md:ml-4"
              >
                <PlusIcon className="h-5 w-5" />
              </button>
            )}
          </div>

          <div className="mt-3 text-right">
            <LoadingButton
              onClick={onSaveRules}
              isLoading={setDeviceRulesState.status === "pending"}
              form="deviveSettingsForm"
              variant="success"
              type="button"
            >
              {t("device.events.settings.save", "save rule")}
            </LoadingButton>
          </div>
        </div>
      )}
    </div>
  );
}
