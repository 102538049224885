var __defProp = Object.defineProperty;
var __export = (target, all) => {
  for (var name in all)
    __defProp(target, name, { get: all[name], enumerable: true });
};

// src/types.ts
var Device;
((Device2) => {
  Device2.rulesShadowName = "rules";
  Device2.configShadowName = "config";
})(Device || (Device = {}));
var Application;
((Application2) => {
  let Notifications;
  ((Notifications2) => {
    Notifications2.thresholdViolationMessageType = "thresholdViolation";
  })(Notifications = Application2.Notifications || (Application2.Notifications = {}));
})(Application || (Application = {}));

// src/metrics.ts
var Phase = /* @__PURE__ */ ((Phase2) => {
  Phase2[Phase2["threePhase"] = 0] = "threePhase";
  Phase2[Phase2["phase1"] = 1] = "phase1";
  Phase2[Phase2["phase2"] = 2] = "phase2";
  Phase2[Phase2["phase3"] = 3] = "phase3";
  return Phase2;
})(Phase || {});
var getAggregateName = (name, aggregateType) => {
  if (aggregateType == "sum" || aggregateType == "avg") {
    return `${name}`;
  } else {
    return `${name}_${aggregateType}`;
  }
};
var units = {
  none: {
    id: "none",
    name: ""
  },
  hour: {
    id: "prefix:h",
    name: "h"
  },
  degree: {
    id: "degree",
    name: "\xB0"
  },
  hertz: {
    id: "hertz",
    name: "Hz"
  },
  ampere: {
    id: "amp",
    name: "A"
  },
  volt: {
    id: "volt",
    name: "V"
  },
  voltAmpere: {
    id: "voltamp",
    name: "VA"
  },
  voltAmpereSeconds: {
    id: "voltamps",
    name: "VAs"
  },
  voltAmpereHours: {
    id: "voltamph",
    name: "VAh"
  },
  voltAmpereReactive: {
    id: "voltampreact",
    name: "var"
  },
  voltAmpereSecondsReactive: {
    id: "voltampreacts",
    name: "vars"
  },
  watt: {
    id: "watt",
    name: "W"
  },
  wattSeconds: {
    id: "watts",
    name: "watts"
  },
  wattHour: {
    id: "watth",
    name: "Wh"
  },
  kiloWattHour: {
    id: "kwh",
    name: "kWh"
  },
  temperatureCelsius: {
    id: "celsius",
    name: "\xB0C"
  }
};
var buildSingleChannelURMS = (phase) => {
  return {
    name: `urms_${phase}`,
    type: "number",
    persist: false,
    unit: units.volt,
    displayPrecision: 1,
    min: 0,
    max: 253,
    nominal: 230,
    phase,
    aggregates: ["avg", "min", "max"]
  };
};
var buildSingleChannelIRMS = (phase) => {
  return {
    name: `irms_${phase}`,
    type: "number",
    persist: false,
    unit: units.ampere,
    displayPrecision: 1,
    min: 0,
    max: 16,
    phase,
    aggregates: ["avg", "min", "max"]
  };
};
var buildSingleChannelPower = (phase) => {
  return {
    name: `p_${phase}`,
    type: "number",
    persist: false,
    unit: units.watt,
    displayPrecision: 1,
    min: 0,
    max: 11e3,
    phase
  };
};
var metrics = {
  urms_1: buildSingleChannelURMS(1 /* phase1 */),
  urms_2: buildSingleChannelURMS(2 /* phase2 */),
  urms_3: buildSingleChannelURMS(3 /* phase3 */),
  uc: {
    name: "uc",
    type: "number",
    persist: true,
    max: 400,
    min: 0,
    nominal: 400,
    phase: 0 /* threePhase */,
    unit: units.volt,
    displayPrecision: 1,
    aggregates: ["avg", "min", "max"]
  },
  irms_1: buildSingleChannelIRMS(1 /* phase1 */),
  irms_2: buildSingleChannelIRMS(2 /* phase2 */),
  irms_3: buildSingleChannelIRMS(3 /* phase3 */),
  irms_n: {
    name: "irms_n",
    type: "number",
    persist: false,
    unit: units.ampere,
    displayPrecision: 1,
    min: 0,
    max: 16,
    phase: 0 /* threePhase */,
    aggregates: ["avg", "min", "max"]
  },
  ic: {
    name: "ic",
    type: "number",
    persist: true,
    unit: units.ampere,
    displayPrecision: 1,
    min: 0,
    max: 16,
    phase: 0 /* threePhase */,
    aggregates: ["avg", "min", "max"]
  },
  p_1: buildSingleChannelPower(1 /* phase1 */),
  p_2: buildSingleChannelPower(2 /* phase2 */),
  p_3: buildSingleChannelPower(3 /* phase3 */),
  p_t: {
    name: "p_t",
    type: "number",
    persist: true,
    unit: units.watt,
    displayPrecision: 1,
    min: 0,
    max: 6.4,
    phase: 0 /* threePhase */,
    aggregates: ["avg", "min", "max"]
  },
  q_t: {
    name: "q_t",
    type: "number",
    persist: true,
    unit: units.voltAmpereReactive,
    displayPrecision: 2,
    min: 0,
    max: 6.4,
    phase: 0 /* threePhase */,
    aggregates: ["avg", "min", "max"]
  },
  ea_fwd_t_d: {
    name: "ea_fwd_t_d",
    type: "number",
    persist: true,
    unit: units.wattSeconds,
    displayPrecision: 2,
    aggregates: ["sum"],
    tenantAggregates: ["sum"],
    phase: 0 /* threePhase */
  },
  er_t_d: {
    name: "er_t_d",
    type: "number",
    persist: true,
    unit: units.voltAmpereSecondsReactive,
    displayPrecision: 2,
    aggregates: ["sum"],
    tenantAggregates: ["sum"],
    phase: 0 /* threePhase */
  },
  pf_t: {
    name: "pf_t",
    type: "number",
    persist: true,
    unit: units.none,
    displayPrecision: 1,
    aggregates: ["avg", "min", "max"],
    phase: 0 /* threePhase */
  },
  tplug: {
    name: "tplug",
    type: "number",
    persist: true,
    unit: units.temperatureCelsius,
    displayPrecision: 1,
    nominal: 22,
    max: 120,
    aggregates: ["avg", "min", "max"],
    phase: 0 /* threePhase */
  },
  f: {
    name: "f",
    type: "number",
    persist: false,
    aggregates: ["min", "max"],
    unit: units.hertz,
    displayPrecision: 2,
    min: 0,
    max: 60,
    nominal: 50
  }
};
var momentaryMetrics = () => {
  return Object.values(metrics).filter((value) => !value.persist);
};
var persistentMetrics = () => {
  return Object.values(metrics).filter((value) => value.persist);
};
var createDeviceReverseMap = (metrics4) => {
  const reverseMap = {};
  if (metrics4) {
    metrics4.forEach((metric) => {
      if (metric.type === "number" && metric.aggregates !== void 0) {
        return metric.aggregates.forEach((p) => {
          if (p === "avg" || p === "sum") {
            reverseMap[metric.name] = {
              name: metric.name,
              aggregationFunction: p
            };
          } else {
            reverseMap[`${metric.name}_${p}`] = {
              name: metric.name,
              aggregationFunction: p
            };
          }
        });
      }
    });
  }
  return reverseMap;
};
var createTenantReverseMap = (metrics4) => {
  const reverseMap = {};
  if (metrics4) {
    metrics4.forEach((metric) => {
      if (metric.type === "number" && metric.tenantAggregates !== void 0) {
        return metric.tenantAggregates.forEach((p) => {
          if (p === "avg" || p === "sum") {
            reverseMap[metric.name] = {
              name: metric.name,
              aggregationFunction: p
            };
          } else {
            reverseMap[`${metric.name}_${p}`] = {
              name: metric.name,
              aggregationFunction: p
            };
          }
        });
      }
    });
  }
  return reverseMap;
};

// src/energyTariff.ts
var getRateFromTariffRates = (tariffRates, currentDate) => {
  if (tariffRates.length) {
    tariffRates.sort((a, b) => {
      return a.from.getTime() - b.from.getTime();
    });
    let tR = null;
    tariffRates.forEach((tariffRate) => {
      if (tariffRate.from <= currentDate) {
        tR = tariffRate;
      }
    });
    return tR;
  }
  return null;
};
var getHourRateFromHours = (hourRates, time) => {
  if (hourRates.length) {
    const hourOfTheDay = time.getUTCHours();
    hourRates.sort((a, b) => {
      return b.start - a.start;
    });
    for (let i = 0; i < hourRates.length; i++) {
      const hour = hourRates[i];
      if (hour.start <= hourOfTheDay) {
        return hour;
      }
    }
  }
  return null;
};
var calculateEnergyCost = (datum, tariffRates, time) => {
  const costs = {};
  const tariffRate = getRateFromTariffRates(
    tariffRates,
    time
  );
  if (tariffRate != null && tariffRate.rate) {
    const hourRate = getHourRateFromHours(
      tariffRate.rate,
      time
    );
    if (hourRate != null && hourRate.metrics) {
      for (const [key, value] of Object.entries(datum)) {
        if (hourRate.metrics.hasOwnProperty(key)) {
          const rate = hourRate.metrics[key];
          if (rate) {
            const costKey = `${key}_cost`;
            costs[costKey] = value ? rate.rate * Math.abs(value) : 0;
          }
        }
      }
    }
  }
  return costs;
};
var getMeasurementRateFromTariffRates = (measurement, tariffRates, time) => {
  const tariffRate = getRateFromTariffRates(
    tariffRates,
    time
  );
  if (tariffRate != null && tariffRate.rate) {
    const hourRate = getHourRateFromHours(
      tariffRate.rate,
      time
    );
    if (hourRate != null && hourRate.metrics) {
      if (hourRate.metrics.hasOwnProperty(measurement) && hourRate.metrics[measurement] !== void 0) {
        return hourRate.metrics[measurement];
      }
    }
  }
  return null;
};
var calculateEnergyCostRateForBaseCurrencyUnitPerKiloWattHour = (euroPerKiloWattHour) => {
  const centsToEuroFactor = 100;
  const exponentFactor = 1e11;
  const secondsToHourFactor = 3600;
  const kiloWattToWattFactor = 1e3;
  const centsPerKiloWattHour = euroPerKiloWattHour / centsToEuroFactor;
  const centsPerWattHour = centsPerKiloWattHour / kiloWattToWattFactor;
  const factoredCentsPerWattHour = centsPerWattHour * exponentFactor;
  const factor = exponentFactor * secondsToHourFactor / centsToEuroFactor;
  return {
    originalRate: euroPerKiloWattHour,
    factor,
    rate: Math.ceil(factoredCentsPerWattHour)
  };
};

// src/util.ts
import {
  getValueFormat,
  toFixed,
  formattedValueToString
} from "@nantis/grafana-data";
var formatDate = (value, format, locale) => {
  const options = toOptions(format, "date");
  return options === null ? value : new Intl.DateTimeFormat(locale, options).format(value);
};
var formatLocalDateWithTimezone = (value, format, locale, timezone) => {
  const options = toOptions(format, "localDateWithTimezone");
  if (options === null)
    return value;
  return new Intl.DateTimeFormat(locale, {
    timeZone: timezone,
    ...options
  }).format(value);
};
var toJsonString = (format, specifier) => {
  const inner = format.trim().replace(specifier, "").replace("(", "").replace(")", "").split(";").map(
    (param) => param.split(":").map((name) => `"${name.trim()}"`).join(":")
  ).join(",");
  return "{" + inner + "}";
};
var toOptions = (format, specifier) => {
  if (format.trim() === specifier || format.trim() === `${specifier}()`) {
    return {};
  } else {
    try {
      return JSON.parse(toJsonString(format, specifier));
    } catch (error) {
      console.error(error);
      return null;
    }
  }
};
var formatDatum = (value, format) => {
  const options = toOptions(format, "datum");
  const decimalsOverride = options == null ? void 0 : options.decimals;
  const dH = getMetricHelper(value.property);
  const decimals = decimalsOverride ?? dH.decimals;
  return formattedValueToString(dH.format(value.value, decimals));
};
var formatNumber = (value, format, lng) => {
  const options = toOptions(format, "number");
  return options === null ? value : new Intl.NumberFormat(lng, options).format(value);
};
var getDatum = (measurement) => {
  if (measurement) {
    const m = Object.keys(metrics).find(
      (value) => measurement.startsWith(value)
    );
    if (m) {
      const metricType = metrics[m];
      if (metricType) {
        return metricType;
      }
    }
  }
  return {
    unit: units.none
  };
};
var getMetricHelper = (measurement) => {
  const m = getDatum(measurement);
  let formatter = getValueFormat(m.unit.id);
  if (formatter == null) {
    formatter = (value, decimals = 5) => {
      if (value) {
        return {
          text: toFixed(value, decimals) + m.unit.name
        };
      }
      return {
        text: "" + m.unit.name
      };
    };
  }
  return {
    unit: m.unit,
    format: formatter,
    decimals: m.decimals,
    range: m.range ?? {
      max: 1e3,
      min: 0,
      nominal: 10
    }
  };
};

// src/tiers.ts
function getDeviceConfiguration(deviceType, tier) {
  let configuration = {
    findMe: false,
    mqttTrace: false,
    alarmsEnabled: false,
    measEnabled: false,
    // Default 1h
    connectionInterval: 60 * 60 * 12,
    measurementInterval: 60 * 60 * 12
  };
  switch (tier) {
    case "tier0":
      configuration.measurementInterval = 60 * 60;
      configuration.connectionInterval = 60 * 60;
      break;
    case "tier1":
      configuration.measurementInterval = 60 * 15;
      configuration.connectionInterval = 60 * 15;
      configuration.measEnabled = true;
      configuration.alarmsEnabled = true;
      break;
  }
  return configuration;
}
var plans = {
  tier0: {
    tier: "tier0",
    features: []
  },
  tier1: {
    tier: "tier1",
    features: ["historicalData", "events"]
  }
};
function getTierPlan(tier) {
  if (plans.hasOwnProperty(tier)) {
    return plans[tier];
  } else {
    return plans.tier0;
  }
}

// src/cellular.ts
var getReceptionStrength = (cellular3) => {
  if (!cellular3) {
    return "no-reception";
  }
  const { rssi, rsrp, rsrq } = cellular3;
  if (rsrp != void 0) {
    if (rsrp > -89) {
      return "excellent";
    } else if (rsrp > -105) {
      return "good";
    } else if (rsrp > -115) {
      return "fair";
    } else if (rsrp > -120) {
      return "poor";
    } else {
      return "no-reception";
    }
  }
  if (rsrq != void 0) {
    if (rsrq > -10) {
      return "excellent";
    } else if (rsrq > -15) {
      return "good";
    } else if (rsrq > -17) {
      return "fair";
    } else if (rsrq > -18) {
      return "poor";
    } else {
      return "no-reception";
    }
  }
  if (rssi >= -67) {
    return "excellent";
  } else if (rssi >= -80) {
    return "good";
  } else if (rssi >= -90) {
    return "fair";
  }
  if (rssi >= -95) {
    return "poor";
  } else {
    return "no-reception";
  }
};

// src/translations/de.json
var de_exports = {};
__export(de_exports, {
  cellular: () => cellular,
  default: () => de_default,
  device: () => device,
  metrics: () => metrics2
});
var metrics2 = {
  urms_1: {
    label: "Phasenspannung L1"
  },
  urms_2: {
    label: "Phasenspannung L2"
  },
  urms_3: {
    label: "Phasenspannung L3"
  },
  uc: {
    label: "Kollektive Summenspannung"
  },
  irms_1: {
    label: "RMS Strom L1"
  },
  irms_2: {
    label: "RMS Strom L2"
  },
  irms_3: {
    label: "RMS Strom L3"
  },
  irms_n: {
    label: "RMS Strom N"
  },
  ic: {
    label: "Kollektiver Summenstrom"
  },
  p_1: {
    label: "Wirkleistung L1"
  },
  p_2: {
    label: "Wirkleistung L2"
  },
  p_3: {
    label: "Wirkleistung L3"
  },
  p_t: {
    label: "Wirkleistung gesamt"
  },
  q_t: {
    label: "Blindleistung gesamt"
  },
  s_t: {
    label: "Scheinleistung gesamt"
  },
  ea_t: {
    label: "Wirkenergie gesamt"
  },
  ea_fwd_t: {
    label: "Wirkenergie Bezug gesamt",
    counter: "Z\xE4hler Energie aktiv"
  },
  ea_rev_t: {
    label: "Wirkenergie Einspeisung gesamt"
  },
  er_t: {
    label: "Blindenergie gesamt",
    counter: "Z\xE4hler Blindenergie reaktiv"
  },
  er_fwd_t: {
    label: "Blindenergie positiv gesamt"
  },
  er_rev_t: {
    label: "Blindenergie negativ gesamt"
  },
  eapp_t: {
    label: "Scheinenergie gesamt"
  },
  ea_t_d: {
    label: "Wirkenergie gesamt Differenz"
  },
  ea_fwd_t_d: {
    label: "Wirkenergie Bezug gesamt Differenz"
  },
  ea_fwd_t_d_cost: {
    label: "Wirkenergie Bezug gesamt Differenz Kosten"
  },
  ea_rev_t_d: {
    label: "Wirkenergie Einspeisung gesamt Differenz"
  },
  er_t_d: {
    label: "Blindenergie gesamt Differenz"
  },
  er_t_d_cost: {
    label: "Blindenergie gesamt Differenz Kosten"
  },
  er_fwd_t_d: {
    label: "Blindenergie positiv gesamt Differenz"
  },
  er_rev_t_d: {
    label: "Blindenergie negativ gesamt Differenz"
  },
  f: {
    label: "Netzfrequenz"
  },
  pf_t: {
    label: "Power Factor gesamt"
  },
  tplug: {
    label: "Steckertemperatur"
  },
  tplug_min: {
    label: "Steckertemperatur minimal"
  },
  tplug_max: {
    label: "Steckertemperatur maximal"
  },
  upeak_1: {
    label: "Peakspannung L1"
  },
  upeak_2: {
    label: "Peakspannung L2"
  },
  upeak_3: {
    label: "Peakspannung L3"
  },
  psi_1: {
    label: "Phasenwinkel Spannung L1"
  },
  psi_2: {
    label: "Phasenwinkel Spannung L2"
  },
  psi_3: {
    label: "Phasenwinkel Spannung L3"
  },
  pa_fun_t: {
    label: "Wirkleistung gesamt Grundschwingung"
  },
  pa_har_t: {
    label: "Wirkleistung gesamt Oberschwingungen"
  },
  ea_fun_t: {
    label: "Wirkenergie gesamt Grundschwingung"
  },
  ea_har_t: {
    label: "Wirkenergie gesamt Oberschwingungen"
  },
  phs: {
    label: "Phasenreihenfolge",
    help: "Die Phasenreihenfolge zeigt an ob ein rechtes Drehfeld zwischen L1, L2 und L3 anliegt",
    okay: "In Ordnung",
    error: "Fehler"
  },
  i: {
    label: "Strom"
  },
  u: {
    label: "Spannung"
  },
  p: {
    label: "Leistung"
  },
  whp: {
    label: "Betriebsstunden eingesteckt"
  },
  whc: {
    label: "Betriebsstunden aktiv"
  }
};
var cellular = {
  mccmnc: "Mobile Country Code / Mobile Network Code",
  rat: "Radio Access Technology",
  rssi: "Receiver Signal Strength Indicator",
  cellId: "Cell Identifier",
  lac: "Location Area Code"
};
var device = {
  fwv: {
    label: "Firmware Version"
  },
  up: {
    label: "Uptime"
  }
};
var de_default = {
  metrics: metrics2,
  cellular,
  device
};

// src/translations/en.json
var en_exports = {};
__export(en_exports, {
  cellular: () => cellular2,
  default: () => en_default,
  device: () => device2,
  metrics: () => metrics3
});
var metrics3 = {
  urms_1: {
    label: "phase voltage L1"
  },
  urms_2: {
    label: "phase voltage L2"
  },
  urms_3: {
    label: "phase voltage L3"
  },
  uc: {
    label: "collective sum voltage"
  },
  irms_1: {
    label: "RMS Strom L1"
  },
  irms_2: {
    label: "RMS current L2"
  },
  irms_3: {
    label: "RMS current L3"
  },
  irms_n: {
    label: "RMS current N"
  },
  ic: {
    label: "collective sum current"
  },
  p_1: {
    label: "active power L1"
  },
  p_2: {
    label: "active power L2"
  },
  p_3: {
    label: "active power L3"
  },
  p_t: {
    label: "active power total"
  },
  q_t: {
    label: "reactive power total"
  },
  s_t: {
    label: "apparent power total"
  },
  ea_t: {
    label: "active energy total"
  },
  ea_fwd_t: {
    label: "active energy total import",
    counter: "active energy meter"
  },
  ea_rev_t: {
    label: "active energy total export"
  },
  er_t: {
    label: "reactive energy total",
    counter: "reactive energy meter"
  },
  er_fwd_t: {
    label: "reactive energy positive total"
  },
  er_rev_t: {
    label: "reactive energy negative total"
  },
  eapp_t: {
    label: "apparent energy total"
  },
  ea_t_d: {
    label: "active energy total delta"
  },
  ea_fwd_t_d: {
    label: "active energy import delta"
  },
  ea_fwd_t_d_cost: {
    label: "active energy import total cost"
  },
  ea_rev_t_d: {
    label: "active energy export total delta"
  },
  er_t_d: {
    label: "reactive energy total delta"
  },
  er_t_d_cost: {
    label: "reactive energy total cost"
  },
  er_fwd_t_d: {
    label: "reactive energy positive total delta"
  },
  er_rev_t_d: {
    label: "reactive energy negative total delta"
  },
  f: {
    label: "mains frequency"
  },
  pf_t: {
    label: "power factor total"
  },
  tplug: {
    label: "plug temperature"
  },
  tplug_min: {
    label: "plug temperature minimum"
  },
  tplug_max: {
    label: "plug temperature maximum"
  },
  upeak_1: {
    label: "peak voltage L1"
  },
  upeak_2: {
    label: "peak voltage L2"
  },
  upeak_3: {
    label: "peak voltage L3"
  },
  psi_1: {
    label: "phase angle voltage L1"
  },
  psi_2: {
    label: "phase angle voltage L1"
  },
  psi_3: {
    label: "phase angle voltage L1"
  },
  pa_fun_t: {
    label: "active power total fundamental"
  },
  pa_har_t: {
    label: "active power total harmonics"
  },
  ea_fun_t: {
    label: "active energy total fundamental"
  },
  ea_har_t: {
    label: "active energy total harmonics"
  },
  phs: {
    label: "phase order",
    help: "the phase order indicates if the phase order is right handed",
    okay: "okay",
    error: "error"
  },
  i: {
    label: "current"
  },
  u: {
    label: "voltage"
  },
  p: {
    label: "power"
  },
  whp: {
    label: "operating hours plugged in"
  },
  whc: {
    label: "operating hours active"
  }
};
var cellular2 = {
  mccmnc: "Mobile Country Code / Mobile Network Code",
  rat: "Radio Access Technology",
  rssi: "Receiver Signal Strength Indicator",
  cellId: "Cell Identifier",
  lac: "Location Area Code"
};
var device2 = {
  fwv: {
    label: "firmware version"
  },
  up: {
    label: "uptime"
  }
};
var en_default = {
  metrics: metrics3,
  cellular: cellular2,
  device: device2
};

// src/translations/index.ts
var translationsDe = de_exports;
var translationsEn = en_exports;
export {
  Application,
  Device,
  Phase,
  calculateEnergyCost,
  calculateEnergyCostRateForBaseCurrencyUnitPerKiloWattHour,
  createDeviceReverseMap,
  createTenantReverseMap,
  formatDate,
  formatDatum,
  formatLocalDateWithTimezone,
  formatNumber,
  getAggregateName,
  getDatum,
  getDeviceConfiguration,
  getHourRateFromHours,
  getMeasurementRateFromTariffRates,
  getMetricHelper,
  getRateFromTariffRates,
  getReceptionStrength,
  getTierPlan,
  metrics,
  momentaryMetrics,
  persistentMetrics,
  plans,
  toJsonString,
  toOptions,
  translationsDe,
  translationsEn,
  units
};
