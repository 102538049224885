var __defProp = Object.defineProperty;
var __export = (target, all) => {
  for (var name in all)
    __defProp(target, name, { get: all[name], enumerable: true });
};

// src/utils/object.ts
var objRemoveUndefined = (obj) => {
  return Object.keys(obj).reduce((acc, key) => {
    if (obj[key] !== void 0) {
      acc[key] = obj[key];
    }
    return acc;
  }, {});
};

// src/utils/selectUtils.ts
var toOption = (value) => ({
  label: value,
  value
});

// src/utils/anyToNumber.ts
import { toNumber } from "lodash";
function anyToNumber(value) {
  if (typeof value === "number") {
    return value;
  }
  if (value === "" || value === null || value === void 0 || Array.isArray(value)) {
    return NaN;
  }
  if (typeof value === "boolean") {
    return value ? 1 : 0;
  }
  return toNumber(value);
}

// src/datetime/datemath.ts
var datemath_exports = {};
__export(datemath_exports, {
  isMathString: () => isMathString,
  isValid: () => isValid,
  parse: () => parse,
  parseDateMath: () => parseDateMath,
  roundToFiscal: () => roundToFiscal
});
import { includes, isDate } from "lodash";

// src/datetime/moment_wrapper.ts
import moment from "moment";
var ISO_8601 = moment.ISO_8601;
var setLocale = (language) => {
  moment.locale(language);
};
var getLocale = () => {
  return moment.locale();
};
var getLocaleData = () => {
  return moment.localeData();
};
var isDateTime = (value) => {
  return moment.isMoment(value);
};
var toUtc = (input, formatInput) => {
  return moment.utc(input, formatInput);
};
var toDuration = (input, unit) => {
  return moment.duration(
    input,
    unit
  );
};
var dateTime = (input, formatInput) => {
  return moment(input, formatInput);
};
var dateTimeAsMoment = (input) => {
  return dateTime(input);
};
var dateTimeForTimeZone = (timezone, input, formatInput) => {
  if (timezone === "utc") {
    return toUtc(input, formatInput);
  }
  return dateTime(input, formatInput);
};
var getWeekdayIndex = (day) => {
  return moment.weekdays().findIndex((wd) => wd.toLowerCase() === day.toLowerCase());
};
var setWeekStart = (weekStart) => {
  const suffix = "-weekStart";
  const language = getLocale().replace(suffix, "");
  const dow = weekStart ? getWeekdayIndex(weekStart) : -1;
  if (dow !== -1) {
    moment.locale(language + suffix, {
      parentLocale: language,
      week: {
        dow
      }
    });
  } else {
    setLocale(language);
  }
};

// src/datetime/datemath.ts
var units = ["y", "M", "w", "d", "h", "m", "s", "Q"];
function isMathString(text) {
  if (!text) {
    return false;
  }
  if (typeof text === "string" && (text.substring(0, 3) === "now" || text.includes("||"))) {
    return true;
  } else {
    return false;
  }
}
function parse(text, roundUp, timezone, fiscalYearStartMonth) {
  if (!text) {
    return void 0;
  }
  if (typeof text !== "string") {
    if (isDateTime(text)) {
      return text;
    }
    if (isDate(text)) {
      return dateTime(text);
    }
    return void 0;
  } else {
    let time;
    let mathString = "";
    let index2;
    let parseString2;
    if (text.substring(0, 3) === "now") {
      time = dateTimeForTimeZone(timezone);
      mathString = text.substring("now".length);
    } else {
      index2 = text.indexOf("||");
      if (index2 === -1) {
        parseString2 = text;
        mathString = "";
      } else {
        parseString2 = text.substring(0, index2);
        mathString = text.substring(index2 + 2);
      }
      time = dateTime(parseString2, ISO_8601);
    }
    if (!mathString.length) {
      return time;
    }
    return parseDateMath(mathString, time, roundUp, fiscalYearStartMonth);
  }
}
function isValid(text) {
  const date = parse(text);
  if (!date) {
    return false;
  }
  if (isDateTime(date)) {
    return date.isValid();
  }
  return false;
}
function parseDateMath(mathString, time, roundUp, fiscalYearStartMonth = 0) {
  const strippedMathString = mathString.replace(/\s/g, "");
  const dateTime2 = time;
  let i = 0;
  const len = strippedMathString.length;
  while (i < len) {
    const c = strippedMathString.charAt(i++);
    let type;
    let num;
    let unit;
    let isFiscal2 = false;
    if (c === "/") {
      type = 0;
    } else if (c === "+") {
      type = 1;
    } else if (c === "-") {
      type = 2;
    } else {
      return void 0;
    }
    if (isNaN(parseInt(strippedMathString.charAt(i), 10))) {
      num = 1;
    } else if (strippedMathString.length === 2) {
      num = parseInt(strippedMathString.charAt(i), 10);
    } else {
      const numFrom = i;
      while (!isNaN(parseInt(strippedMathString.charAt(i), 10))) {
        i++;
        if (i > 10) {
          return void 0;
        }
      }
      num = parseInt(strippedMathString.substring(numFrom, i), 10);
    }
    if (type === 0) {
      if (num !== 1) {
        return void 0;
      }
    }
    unit = strippedMathString.charAt(i++);
    if (unit === "f") {
      unit = strippedMathString.charAt(i++);
      isFiscal2 = true;
    }
    if (!includes(units, unit)) {
      return void 0;
    } else {
      if (type === 0) {
        if (roundUp) {
          if (isFiscal2) {
            roundToFiscal(fiscalYearStartMonth, dateTime2, unit, roundUp);
          } else {
            dateTime2.endOf(unit);
          }
        } else {
          if (isFiscal2) {
            roundToFiscal(fiscalYearStartMonth, dateTime2, unit, roundUp);
          } else {
            dateTime2.startOf(unit);
          }
        }
      } else if (type === 1) {
        dateTime2.add(num, unit);
      } else if (type === 2) {
        dateTime2.subtract(num, unit);
      }
    }
  }
  return dateTime2;
}
function roundToFiscal(fyStartMonth, dateTime2, unit, roundUp) {
  switch (unit) {
    case "y":
      if (roundUp) {
        roundToFiscal(fyStartMonth, dateTime2, unit, false).add(11, "M").endOf("M");
      } else {
        dateTime2.subtract((dateTime2.month() - fyStartMonth + 12) % 12, "M").startOf("M");
      }
      return dateTime2;
    case "Q":
      if (roundUp) {
        roundToFiscal(fyStartMonth, dateTime2, unit, false).add(2, "M").endOf("M");
      } else {
        dateTime2.subtract((dateTime2.month() - fyStartMonth + 3) % 3, "M").startOf("M");
      }
      return dateTime2;
    default:
      return void 0;
  }
}

// src/datetime/rangeutil.ts
var rangeutil_exports = {};
__export(rangeutil_exports, {
  calculateInterval: () => calculateInterval,
  convertRawToRange: () => convertRawToRange,
  describeInterval: () => describeInterval,
  describeTextRange: () => describeTextRange,
  describeTimeRange: () => describeTimeRange,
  describeTimeRangeAbbreviation: () => describeTimeRangeAbbreviation,
  intervalToMs: () => intervalToMs,
  intervalToSeconds: () => intervalToSeconds,
  isFiscal: () => isFiscal,
  isRelativeTimeRange: () => isRelativeTimeRange,
  isValidTimeSpan: () => isValidTimeSpan,
  msRangeToTimeString: () => msRangeToTimeString,
  relativeToTimeRange: () => relativeToTimeRange,
  roundInterval: () => roundInterval,
  secondsToHms: () => secondsToHms,
  timeRangeToRelative: () => timeRangeToRelative
});
import { each, has } from "lodash";

// src/datetime/formatter.ts
import moment2 from "moment-timezone";

// src/datetime/formats.ts
var DEFAULT_SYSTEM_DATE_FORMAT = "YYYY-MM-DD HH:mm:ss";
var SystemDateFormatsState = class {
  constructor() {
    this.fullDate = DEFAULT_SYSTEM_DATE_FORMAT;
    this.interval = {
      second: "HH:mm:ss",
      minute: "HH:mm",
      hour: "MM/DD HH:mm",
      day: "MM/DD",
      month: "YYYY-MM",
      year: "YYYY"
    };
  }
  update(settings) {
    this.fullDate = settings.fullDate;
    this.interval = settings.interval;
    if (settings.useBrowserLocale) {
      this.useBrowserLocale();
    }
  }
  getFullDateMS() {
    return this.fullDate.replace("ss", "ss.SSS");
  }
  useBrowserLocale() {
    this.fullDate = localTimeFormat({
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit"
    });
    this.interval.second = localTimeFormat(
      { hour: "2-digit", minute: "2-digit", second: "2-digit", hour12: false },
      null,
      this.interval.second
    );
    this.interval.minute = localTimeFormat(
      { hour: "2-digit", minute: "2-digit", hour12: false },
      null,
      this.interval.minute
    );
    this.interval.hour = localTimeFormat(
      {
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
        hour12: false
      },
      null,
      this.interval.hour
    );
    this.interval.day = localTimeFormat(
      { month: "2-digit", day: "2-digit", hour12: false },
      null,
      this.interval.day
    );
    this.interval.month = localTimeFormat(
      { year: "numeric", month: "2-digit", hour12: false },
      null,
      this.interval.month
    );
  }
  getTimeFieldUnit(useMsResolution) {
    return `time:${useMsResolution ? this.getFullDateMS() : this.fullDate}`;
  }
};
function localTimeFormat(options, locale2, fallback) {
  if (missingIntlDateTimeFormatSupport()) {
    return fallback ?? DEFAULT_SYSTEM_DATE_FORMAT;
  }
  if (!locale2 && typeof navigator !== "undefined" && navigator) {
    locale2 = [...(navigator == null ? void 0 : navigator.languages) ?? []];
  }
  const dateTimeFormat2 = new Intl.DateTimeFormat(locale2 || void 0, options);
  const parts = dateTimeFormat2.formatToParts(/* @__PURE__ */ new Date());
  const hour12 = dateTimeFormat2.resolvedOptions().hour12;
  const mapping = {
    year: "YYYY",
    month: "MM",
    day: "DD",
    hour: hour12 ? "hh" : "HH",
    minute: "mm",
    second: "ss",
    weekday: "ddd",
    era: "N",
    dayPeriod: "A",
    timeZoneName: "Z"
  };
  return parts.map((part) => mapping[part.type] || part.value).join("");
}
var systemDateFormats = new SystemDateFormatsState();
var missingIntlDateTimeFormatSupport = () => {
  return !("DateTimeFormat" in Intl) || !("formatToParts" in Intl.DateTimeFormat.prototype);
};

// src/datetime/common.ts
var defaultTimeZoneResolver = () => DefaultTimeZone;
var setTimeZoneResolver = (resolver) => {
  defaultTimeZoneResolver = resolver ?? defaultTimeZoneResolver;
};
var getTimeZone = (options) => {
  return (options == null ? void 0 : options.timeZone) ?? defaultTimeZoneResolver() ?? DefaultTimeZone;
};

// src/datetime/formatter.ts
var dateTimeFormat = (dateInUtc, options) => toTz(dateInUtc, getTimeZone(options)).format(getFormat(options));
var dateTimeFormatISO = (dateInUtc, options) => toTz(dateInUtc, getTimeZone(options)).format();
var dateTimeFormatTimeAgo = (dateInUtc, options) => toTz(dateInUtc, getTimeZone(options)).fromNow();
var dateTimeFormatWithAbbrevation = (dateInUtc, options) => toTz(dateInUtc, getTimeZone(options)).format(
  `${systemDateFormats.fullDate} z`
);
var timeZoneAbbrevation = (dateInUtc, options) => toTz(dateInUtc, getTimeZone(options)).format("z");
var getFormat = (options) => {
  if (options == null ? void 0 : options.defaultWithMS) {
    return (options == null ? void 0 : options.format) ?? systemDateFormats.getFullDateMS();
  }
  return (options == null ? void 0 : options.format) ?? systemDateFormats.fullDate;
};
var toTz = (dateInUtc, timeZone) => {
  const date = dateInUtc;
  const zone = moment2.tz.zone(timeZone);
  if (zone && zone.name) {
    return moment2.utc(date).tz(zone.name);
  }
  switch (timeZone) {
    case "utc":
      return moment2.utc(date);
    default:
      return moment2.utc(date).local();
  }
};

// src/datetime/parser.ts
import moment3 from "moment-timezone";
import { lowerCase } from "lodash";
var dateTimeParse = (value, options) => {
  if (isDateTime(value)) {
    return value;
  }
  if (typeof value === "string") {
    return parseString(value, options);
  }
  return parseOthers(value, options);
};
var parseString = (value, options) => {
  if (value.indexOf("now") !== -1) {
    if (!isValid(value)) {
      return moment3();
    }
    const parsed = parse(
      value,
      options == null ? void 0 : options.roundUp,
      options == null ? void 0 : options.timeZone,
      options == null ? void 0 : options.fiscalYearStartMonth
    );
    return parsed || moment3();
  }
  const timeZone = getTimeZone(options);
  const zone = moment3.tz.zone(timeZone);
  const format = (options == null ? void 0 : options.format) ?? systemDateFormats.fullDate;
  if (zone && zone.name) {
    return moment3.tz(value, format, zone.name);
  }
  switch (lowerCase(timeZone)) {
    case "utc":
      return moment3.utc(value, format);
    default:
      return moment3(value, format);
  }
};
var parseOthers = (value, options) => {
  const date = value;
  const timeZone = getTimeZone(options);
  const zone = moment3.tz.zone(timeZone);
  if (zone && zone.name) {
    return moment3.tz(date, zone.name);
  }
  switch (lowerCase(timeZone)) {
    case "utc":
      return moment3.utc(date);
    default:
      return moment3(date);
  }
};

// src/datetime/rangeutil.ts
var spans = {
  s: { display: "second" },
  m: { display: "minute" },
  h: { display: "hour" },
  d: { display: "day" },
  w: { display: "week" },
  M: { display: "month" },
  y: { display: "year" }
};
var rangeOptions = [
  { from: "now/d", to: "now/d", display: "Today" },
  { from: "now/d", to: "now", display: "Today so far" },
  { from: "now/w", to: "now/w", display: "This week" },
  { from: "now/w", to: "now", display: "This week so far" },
  { from: "now/M", to: "now/M", display: "This month" },
  { from: "now/M", to: "now", display: "This month so far" },
  { from: "now/y", to: "now/y", display: "This year" },
  { from: "now/y", to: "now", display: "This year so far" },
  { from: "now-1d/d", to: "now-1d/d", display: "Yesterday" },
  {
    from: "now-2d/d",
    to: "now-2d/d",
    display: "Day before yesterday"
  },
  {
    from: "now-7d/d",
    to: "now-7d/d",
    display: "This day last week"
  },
  { from: "now-1w/w", to: "now-1w/w", display: "Previous week" },
  { from: "now-1M/M", to: "now-1M/M", display: "Previous month" },
  { from: "now-1Q/fQ", to: "now-1Q/fQ", display: "Previous fiscal quarter" },
  { from: "now-1y/y", to: "now-1y/y", display: "Previous year" },
  { from: "now-1y/fy", to: "now-1y/fy", display: "Previous fiscal year" },
  { from: "now-5m", to: "now", display: "Last 5 minutes" },
  { from: "now-15m", to: "now", display: "Last 15 minutes" },
  { from: "now-30m", to: "now", display: "Last 30 minutes" },
  { from: "now-1h", to: "now", display: "Last 1 hour" },
  { from: "now-3h", to: "now", display: "Last 3 hours" },
  { from: "now-6h", to: "now", display: "Last 6 hours" },
  { from: "now-12h", to: "now", display: "Last 12 hours" },
  { from: "now-24h", to: "now", display: "Last 24 hours" },
  { from: "now-2d", to: "now", display: "Last 2 days" },
  { from: "now-7d", to: "now", display: "Last 7 days" },
  { from: "now-30d", to: "now", display: "Last 30 days" },
  { from: "now-90d", to: "now", display: "Last 90 days" },
  { from: "now-6M", to: "now", display: "Last 6 months" },
  { from: "now-1y", to: "now", display: "Last 1 year" },
  { from: "now-2y", to: "now", display: "Last 2 years" },
  { from: "now-5y", to: "now", display: "Last 5 years" },
  { from: "now/fQ", to: "now", display: "This fiscal quarter so far" },
  { from: "now/fQ", to: "now/fQ", display: "This fiscal quarter" },
  { from: "now/fy", to: "now", display: "This fiscal year so far" },
  { from: "now/fy", to: "now/fy", display: "This fiscal year" }
];
var hiddenRangeOptions = [
  { from: "now", to: "now+1m", display: "Next minute" },
  { from: "now", to: "now+5m", display: "Next 5 minutes" },
  { from: "now", to: "now+15m", display: "Next 15 minutes" },
  { from: "now", to: "now+30m", display: "Next 30 minutes" },
  { from: "now", to: "now+1h", display: "Next hour" },
  { from: "now", to: "now+3h", display: "Next 3 hours" },
  { from: "now", to: "now+6h", display: "Next 6 hours" },
  { from: "now", to: "now+12h", display: "Next 12 hours" },
  { from: "now", to: "now+24h", display: "Next 24 hours" },
  { from: "now", to: "now+2d", display: "Next 2 days" },
  { from: "now", to: "now+7d", display: "Next 7 days" },
  { from: "now", to: "now+30d", display: "Next 30 days" },
  { from: "now", to: "now+90d", display: "Next 90 days" },
  { from: "now", to: "now+6M", display: "Next 6 months" },
  { from: "now", to: "now+1y", display: "Next year" },
  { from: "now", to: "now+2y", display: "Next 2 years" },
  { from: "now", to: "now+5y", display: "Next 5 years" }
];
var rangeIndex = {};
each(rangeOptions, (frame) => {
  rangeIndex[frame.from + " to " + frame.to] = frame;
});
each(hiddenRangeOptions, (frame) => {
  rangeIndex[frame.from + " to " + frame.to] = frame;
});
function describeTextRange(expr) {
  const isLast = expr.indexOf("+") !== 0;
  if (expr.indexOf("now") === -1) {
    expr = (isLast ? "now-" : "now") + expr;
  }
  let opt = rangeIndex[expr + " to now"];
  if (opt) {
    return opt;
  }
  if (isLast) {
    opt = { from: expr, to: "now" };
  } else {
    opt = { from: "now", to: expr };
  }
  const parts = /^now([-+])(\d+)(\w)/.exec(expr);
  if (parts) {
    const unit = parts[3];
    const amount = parseInt(parts[2], 10);
    const span = spans[unit];
    if (span) {
      opt.display = isLast ? "Last " : "Next ";
      opt.display += amount + " " + span.display;
      opt.section = span.section;
      if (amount > 1) {
        opt.display += "s";
      }
    }
  } else {
    opt.display = opt.from + " to " + opt.to;
    opt.invalid = true;
  }
  return opt;
}
function describeTimeRange(range, timeZone) {
  const option = rangeIndex[range.from.toString() + " to " + range.to.toString()];
  if (option) {
    return option.display;
  }
  const options = { timeZone };
  if (isDateTime(range.from) && isDateTime(range.to)) {
    return dateTimeFormat(range.from, options) + " to " + dateTimeFormat(range.to, options);
  }
  if (isDateTime(range.from)) {
    const parsed = parse(range.to, true, "utc");
    return parsed ? dateTimeFormat(range.from, options) + " to " + dateTimeFormatTimeAgo(parsed, options) : "";
  }
  if (isDateTime(range.to)) {
    const parsed = parse(range.from, false, "utc");
    return parsed ? dateTimeFormatTimeAgo(parsed, options) + " to " + dateTimeFormat(range.to, options) : "";
  }
  if (range.to.toString() === "now") {
    const res = describeTextRange(range.from);
    return res.display;
  }
  return range.from.toString() + " to " + range.to.toString();
}
var isValidTimeSpan = (value) => {
  if (value.indexOf("$") === 0 || value.indexOf("+$") === 0) {
    return true;
  }
  const info = describeTextRange(value);
  return info.invalid !== true;
};
var describeTimeRangeAbbreviation = (range, timeZone) => {
  if (isDateTime(range.from)) {
    return timeZoneAbbrevation(range.from, { timeZone });
  }
  const parsed = parse(range.from, true);
  return parsed ? timeZoneAbbrevation(parsed, { timeZone }) : "";
};
var convertRawToRange = (raw, timeZone, fiscalYearStartMonth) => {
  const from = dateTimeParse(raw.from, {
    roundUp: false,
    timeZone,
    fiscalYearStartMonth
  });
  const to = dateTimeParse(raw.to, {
    roundUp: true,
    timeZone,
    fiscalYearStartMonth
  });
  if (isMathString(raw.from) || isMathString(raw.to)) {
    return { from, to, raw };
  }
  return { from, to, raw: { from, to } };
};
function isRelativeTime(v) {
  if (typeof v === "string") {
    return v.indexOf("now") >= 0;
  }
  return false;
}
function isFiscal(timeRange) {
  if (typeof timeRange.raw.from === "string" && timeRange.raw.from.indexOf("f") > 0) {
    return true;
  } else if (typeof timeRange.raw.to === "string" && timeRange.raw.to.indexOf("f") > 0) {
    return true;
  }
  return false;
}
function isRelativeTimeRange(raw) {
  return isRelativeTime(raw.from) || isRelativeTime(raw.to);
}
function secondsToHms(seconds) {
  const numYears = Math.floor(seconds / 31536e3);
  if (numYears) {
    return numYears + "y";
  }
  const numDays = Math.floor(seconds % 31536e3 / 86400);
  if (numDays) {
    return numDays + "d";
  }
  const numHours = Math.floor(seconds % 31536e3 % 86400 / 3600);
  if (numHours) {
    return numHours + "h";
  }
  const numMinutes = Math.floor(seconds % 31536e3 % 86400 % 3600 / 60);
  if (numMinutes) {
    return numMinutes + "m";
  }
  const numSeconds = Math.floor(seconds % 31536e3 % 86400 % 3600 % 60);
  if (numSeconds) {
    return numSeconds + "s";
  }
  const numMilliseconds = Math.floor(seconds * 1e3);
  if (numMilliseconds) {
    return numMilliseconds + "ms";
  }
  return "less than a millisecond";
}
function msRangeToTimeString(rangeMs) {
  const rangeSec = Number((rangeMs / 1e3).toFixed());
  const h = Math.floor(rangeSec / 60 / 60);
  const m = Math.floor(rangeSec / 60) - h * 60;
  const s = Number((rangeSec % 60).toFixed());
  let formattedH = h ? h + "h" : "";
  let formattedM = m ? m + "min" : "";
  let formattedS = s ? s + "sec" : "";
  formattedH && formattedM ? formattedH = formattedH + " " : formattedH = formattedH;
  (formattedM || formattedH) && formattedS ? formattedM = formattedM + " " : formattedM = formattedM;
  return formattedH + formattedM + formattedS || "less than 1sec";
}
function calculateInterval(range, resolution, lowLimitInterval) {
  let lowLimitMs = 1;
  if (lowLimitInterval) {
    lowLimitMs = intervalToMs(lowLimitInterval);
  }
  let intervalMs = roundInterval(
    (range.to.valueOf() - range.from.valueOf()) / resolution
  );
  if (lowLimitMs > intervalMs) {
    intervalMs = lowLimitMs;
  }
  return {
    intervalMs,
    interval: secondsToHms(intervalMs / 1e3)
  };
}
var interval_regex = /(-?\d+(?:\.\d+)?)(ms|[Mwdhmsy])/;
var intervals_in_seconds = {
  y: 31536e3,
  M: 2592e3,
  w: 604800,
  d: 86400,
  h: 3600,
  m: 60,
  s: 1,
  ms: 1e-3
};
function describeInterval(str) {
  if (Number(str)) {
    return {
      sec: intervals_in_seconds.s,
      type: "s",
      count: parseInt(str, 10)
    };
  }
  const matches = str.match(interval_regex);
  if (!matches || !has(intervals_in_seconds, matches[2])) {
    throw new Error(
      `Invalid interval string, has to be either unit-less or end with one of the following units: "${Object.keys(
        intervals_in_seconds
      ).join(", ")}"`
    );
  }
  return {
    sec: intervals_in_seconds[matches[2]],
    type: matches[2],
    count: parseInt(matches[1], 10)
  };
}
function intervalToSeconds(str) {
  const info = describeInterval(str);
  return info.sec * info.count;
}
function intervalToMs(str) {
  const info = describeInterval(str);
  return info.sec * 1e3 * info.count;
}
function roundInterval(interval) {
  switch (true) {
    case interval < 15:
      return 10;
    case interval < 35:
      return 20;
    case interval < 75:
      return 50;
    case interval < 150:
      return 100;
    case interval < 350:
      return 200;
    case interval < 750:
      return 500;
    case interval < 1500:
      return 1e3;
    case interval < 3500:
      return 2e3;
    case interval < 7500:
      return 5e3;
    case interval < 12500:
      return 1e4;
    case interval < 17500:
      return 15e3;
    case interval < 25e3:
      return 2e4;
    case interval < 45e3:
      return 3e4;
    case interval < 9e4:
      return 6e4;
    case interval < 21e4:
      return 12e4;
    case interval < 45e4:
      return 3e5;
    case interval < 75e4:
      return 6e5;
    case interval < 105e4:
      return 9e5;
    case interval < 15e5:
      return 12e5;
    case interval < 27e5:
      return 18e5;
    case interval < 54e5:
      return 36e5;
    case interval < 9e6:
      return 72e5;
    case interval < 162e5:
      return 108e5;
    case interval < 324e5:
      return 216e5;
    case interval < 864e5:
      return 432e5;
    case interval < 6048e5:
      return 864e5;
    case interval < 18144e5:
      return 6048e5;
    case interval < 36288e5:
      return 2592e6;
    default:
      return 31536e6;
  }
}
function timeRangeToRelative(timeRange, now = dateTime()) {
  const from = now.unix() - timeRange.from.unix();
  const to = now.unix() - timeRange.to.unix();
  return {
    from,
    to
  };
}
function relativeToTimeRange(relativeTimeRange, now = dateTime()) {
  const from = dateTime(now).subtract(relativeTimeRange.from, "s");
  const to = relativeTimeRange.to === 0 ? dateTime(now) : dateTime(now).subtract(relativeTimeRange.to, "s");
  return {
    from,
    to,
    raw: { from, to }
  };
}

// src/datetime/timezones.ts
import * as moment4 from "moment-timezone";
import { memoize } from "lodash";
var InternalTimeZones = /* @__PURE__ */ ((InternalTimeZones2) => {
  InternalTimeZones2["default"] = "";
  InternalTimeZones2["localBrowserTime"] = "browser";
  InternalTimeZones2["utc"] = "utc";
  return InternalTimeZones2;
})(InternalTimeZones || {});
var timeZoneFormatUserFriendly = (timeZone) => {
  switch (getTimeZone({ timeZone })) {
    case "browser":
      return "Local browser time";
    case "utc":
      return "UTC";
    default:
      return timeZone;
  }
};
var getTimeZoneInfo = (zone, timestamp) => {
  const internal = mapInternal(zone, timestamp);
  if (internal) {
    return internal;
  }
  return mapToInfo(zone, timestamp);
};
var getTimeZones = memoize(
  (includeInternal = false) => {
    const initial = [];
    if (includeInternal === true) {
      initial.push(
        "" /* default */,
        "browser" /* localBrowserTime */,
        "utc" /* utc */
      );
    } else if (includeInternal) {
      initial.push(...includeInternal);
    }
    return moment4.tz.names().reduce((zones, zone) => {
      const countriesForZone = countriesByTimeZone[zone];
      if (!Array.isArray(countriesForZone) || countriesForZone.length === 0) {
        return zones;
      }
      zones.push(zone);
      return zones;
    }, initial);
  }
);
var getTimeZoneGroups = memoize(
  (includeInternal = false) => {
    const timeZones = getTimeZones(includeInternal);
    const groups = timeZones.reduce(
      (groups2, zone) => {
        const delimiter = zone.indexOf("/");
        if (delimiter === -1) {
          const group2 = "";
          groups2[group2] = groups2[group2] ?? [];
          groups2[group2].push(zone);
          return groups2;
        }
        const group = zone.substr(0, delimiter);
        groups2[group] = groups2[group] ?? [];
        groups2[group].push(zone);
        return groups2;
      },
      {}
    );
    return Object.keys(groups).map((name) => ({
      name,
      zones: groups[name]
    }));
  }
);
var mapInternal = (zone, timestamp) => {
  switch (zone) {
    case "utc" /* utc */: {
      return {
        name: "Coordinated Universal Time",
        ianaName: "UTC",
        zone,
        countries: [],
        abbreviation: "UTC, GMT",
        offsetInMins: 0
      };
    }
    case "" /* default */: {
      const tz2 = getTimeZone();
      const isInternal = tz2 === "browser" || tz2 === "utc";
      const info = (isInternal ? mapInternal(tz2, timestamp) : mapToInfo(tz2, timestamp)) ?? {};
      return {
        countries: countriesByTimeZone[tz2] ?? [],
        abbreviation: "",
        offsetInMins: 0,
        ...info,
        ianaName: info.ianaName,
        name: "Default",
        zone
      };
    }
    case "browser" /* localBrowserTime */: {
      const tz2 = moment4.tz.guess(true);
      const info = mapToInfo(tz2, timestamp) ?? {};
      return {
        countries: countriesByTimeZone[tz2] ?? [],
        abbreviation: "Your local time",
        offsetInMins: (/* @__PURE__ */ new Date()).getTimezoneOffset(),
        ...info,
        name: "Browser Time",
        ianaName: info.ianaName,
        zone
      };
    }
    default:
      return void 0;
  }
};
var abbrevationWithoutOffset = (abbrevation) => {
  if (/^(\+|\-).+/.test(abbrevation)) {
    return "";
  }
  return abbrevation;
};
var mapToInfo = (timeZone, timestamp) => {
  const momentTz = moment4.tz.zone(timeZone);
  if (!momentTz) {
    return void 0;
  }
  return {
    name: timeZone,
    ianaName: momentTz.name,
    zone: timeZone,
    countries: countriesByTimeZone[timeZone] ?? [],
    abbreviation: abbrevationWithoutOffset(momentTz.abbr(timestamp)),
    offsetInMins: momentTz.utcOffset(timestamp)
  };
};
var countryByCode = {
  AF: "Afghanistan",
  AX: "Aland Islands",
  AL: "Albania",
  DZ: "Algeria",
  AS: "American Samoa",
  AD: "Andorra",
  AO: "Angola",
  AI: "Anguilla",
  AQ: "Antarctica",
  AG: "Antigua And Barbuda",
  AR: "Argentina",
  AM: "Armenia",
  AW: "Aruba",
  AU: "Australia",
  AT: "Austria",
  AZ: "Azerbaijan",
  BS: "Bahamas",
  BH: "Bahrain",
  BD: "Bangladesh",
  BB: "Barbados",
  BY: "Belarus",
  BE: "Belgium",
  BZ: "Belize",
  BJ: "Benin",
  BM: "Bermuda",
  BT: "Bhutan",
  BO: "Bolivia",
  BA: "Bosnia And Herzegovina",
  BW: "Botswana",
  BV: "Bouvet Island",
  BR: "Brazil",
  IO: "British Indian Ocean Territory",
  BN: "Brunei Darussalam",
  BG: "Bulgaria",
  BF: "Burkina Faso",
  BI: "Burundi",
  KH: "Cambodia",
  CM: "Cameroon",
  CA: "Canada",
  CV: "Cape Verde",
  KY: "Cayman Islands",
  CF: "Central African Republic",
  TD: "Chad",
  CL: "Chile",
  CN: "China",
  CX: "Christmas Island",
  CC: "Cocos (Keeling) Islands",
  CO: "Colombia",
  KM: "Comoros",
  CG: "Congo",
  CD: "Congo, Democratic Republic",
  CK: "Cook Islands",
  CR: "Costa Rica",
  CI: "Cote D'Ivoire",
  HR: "Croatia",
  CU: "Cuba",
  CY: "Cyprus",
  CZ: "Czech Republic",
  DK: "Denmark",
  DJ: "Djibouti",
  DM: "Dominica",
  DO: "Dominican Republic",
  EC: "Ecuador",
  EG: "Egypt",
  SV: "El Salvador",
  GQ: "Equatorial Guinea",
  ER: "Eritrea",
  EE: "Estonia",
  ET: "Ethiopia",
  FK: "Falkland Islands (Malvinas)",
  FO: "Faroe Islands",
  FJ: "Fiji",
  FI: "Finland",
  FR: "France",
  GF: "French Guiana",
  PF: "French Polynesia",
  TF: "French Southern Territories",
  GA: "Gabon",
  GM: "Gambia",
  GE: "Georgia",
  DE: "Germany",
  GH: "Ghana",
  GI: "Gibraltar",
  GR: "Greece",
  GL: "Greenland",
  GD: "Grenada",
  GP: "Guadeloupe",
  GU: "Guam",
  GT: "Guatemala",
  GG: "Guernsey",
  GN: "Guinea",
  GW: "Guinea-Bissau",
  GY: "Guyana",
  HT: "Haiti",
  HM: "Heard Island & Mcdonald Islands",
  VA: "Holy See (Vatican City State)",
  HN: "Honduras",
  HK: "Hong Kong",
  HU: "Hungary",
  IS: "Iceland",
  IN: "India",
  ID: "Indonesia",
  IR: "Iran (Islamic Republic Of)",
  IQ: "Iraq",
  IE: "Ireland",
  IM: "Isle Of Man",
  IL: "Israel",
  IT: "Italy",
  JM: "Jamaica",
  JP: "Japan",
  JE: "Jersey",
  JO: "Jordan",
  KZ: "Kazakhstan",
  KE: "Kenya",
  KI: "Kiribati",
  KR: "Korea",
  KW: "Kuwait",
  KG: "Kyrgyzstan",
  LA: "Lao People's Democratic Republic",
  LV: "Latvia",
  LB: "Lebanon",
  LS: "Lesotho",
  LR: "Liberia",
  LY: "Libyan Arab Jamahiriya",
  LI: "Liechtenstein",
  LT: "Lithuania",
  LU: "Luxembourg",
  MO: "Macao",
  MK: "Macedonia",
  MG: "Madagascar",
  MW: "Malawi",
  MY: "Malaysia",
  MV: "Maldives",
  ML: "Mali",
  MT: "Malta",
  MH: "Marshall Islands",
  MQ: "Martinique",
  MR: "Mauritania",
  MU: "Mauritius",
  YT: "Mayotte",
  MX: "Mexico",
  FM: "Micronesia (Federated States Of)",
  MD: "Moldova",
  MC: "Monaco",
  MN: "Mongolia",
  ME: "Montenegro",
  MS: "Montserrat",
  MA: "Morocco",
  MZ: "Mozambique",
  MM: "Myanmar",
  NA: "Namibia",
  NR: "Nauru",
  NP: "Nepal",
  NL: "Netherlands",
  AN: "Netherlands Antilles",
  NC: "New Caledonia",
  NZ: "New Zealand",
  NI: "Nicaragua",
  NE: "Niger",
  NG: "Nigeria",
  NU: "Niue",
  NF: "Norfolk Island",
  MP: "Northern Mariana Islands",
  NO: "Norway",
  OM: "Oman",
  PK: "Pakistan",
  PW: "Palau",
  PS: "Palestinian Territory (Occupied)",
  PA: "Panama",
  PG: "Papua New Guinea",
  PY: "Paraguay",
  PE: "Peru",
  PH: "Philippines",
  PN: "Pitcairn",
  PL: "Poland",
  PT: "Portugal",
  PR: "Puerto Rico",
  QA: "Qatar",
  RE: "Reunion",
  RO: "Romania",
  RU: "Russian Federation",
  RW: "Rwanda",
  BL: "Saint Barthelemy",
  SH: "Saint Helena",
  KN: "Saint Kitts And Nevis",
  LC: "Saint Lucia",
  MF: "Saint Martin",
  PM: "Saint Pierre And Miquelon",
  VC: "Saint Vincent And Grenadines",
  WS: "Samoa",
  SM: "San Marino",
  ST: "Sao Tome And Principe",
  SA: "Saudi Arabia",
  SN: "Senegal",
  RS: "Serbia",
  SC: "Seychelles",
  SL: "Sierra Leone",
  SG: "Singapore",
  SK: "Slovakia",
  SI: "Slovenia",
  SB: "Solomon Islands",
  SO: "Somalia",
  ZA: "South Africa",
  GS: "South Georgia And Sandwich Isl.",
  ES: "Spain",
  LK: "Sri Lanka",
  SD: "Sudan",
  SR: "Suriname",
  SJ: "Svalbard And Jan Mayen",
  SZ: "Swaziland",
  SE: "Sweden",
  CH: "Switzerland",
  SY: "Syrian Arab Republic",
  TW: "Taiwan",
  TJ: "Tajikistan",
  TZ: "Tanzania",
  TH: "Thailand",
  TL: "Timor-Leste",
  TG: "Togo",
  TK: "Tokelau",
  TO: "Tonga",
  TT: "Trinidad And Tobago",
  TN: "Tunisia",
  TR: "Turkey",
  TM: "Turkmenistan",
  TC: "Turks And Caicos Islands",
  TV: "Tuvalu",
  UG: "Uganda",
  UA: "Ukraine",
  AE: "United Arab Emirates",
  GB: "United Kingdom",
  US: "United States",
  UM: "United States Outlying Islands",
  UY: "Uruguay",
  UZ: "Uzbekistan",
  VU: "Vanuatu",
  VE: "Venezuela",
  VN: "Viet Nam",
  VG: "Virgin Islands, British",
  VI: "Virgin Islands, U.S.",
  WF: "Wallis And Futuna",
  EH: "Western Sahara",
  YE: "Yemen",
  ZM: "Zambia",
  ZW: "Zimbabwe"
};
var countriesByTimeZone = (() => {
  return moment4.tz.countries().reduce((all, code) => {
    const timeZones = moment4.tz.zonesForCountry(code);
    return timeZones.reduce(
      (all2, timeZone) => {
        if (!all2[timeZone]) {
          all2[timeZone] = [];
        }
        const name = countryByCode[code];
        if (!name) {
          return all2;
        }
        all2[timeZone].push({ code, name });
        return all2;
      },
      all
    );
  }, {});
})();

// src/datetime/durationutil.ts
import { intervalToDuration, add } from "date-fns";
var durationMap = {
  years: ["y", "Y", "years"],
  months: ["M", "months"],
  weeks: ["w", "W", "weeks"],
  days: ["d", "D", "days"],
  hours: ["h", "H", "hours"],
  minutes: ["m", "minutes"],
  seconds: ["s", "S", "seconds"]
};
function intervalToAbbreviatedDurationString(interval, includeSeconds = true) {
  const duration = intervalToDuration(interval);
  return Object.entries(duration).reduce((str, [unit, value]) => {
    if (value && value !== 0 && !(unit === "seconds" && !includeSeconds && str)) {
      const padding = str !== "" ? " " : "";
      return str + `${padding}${value}${durationMap[unit][0]}`;
    }
    return str;
  }, "");
}
function parseDuration(durationString) {
  return durationString.split(" ").reduce((acc, value) => {
    const match = value.match(/(\d+)(.+)/);
    const rawLength = match == null ? void 0 : match[1];
    const unit = match == null ? void 0 : match[2];
    if (!(rawLength && unit)) {
      return acc;
    }
    const mapping = Object.entries(durationMap).find(
      ([_, abbreviations]) => abbreviations == null ? void 0 : abbreviations.includes(match[2])
    );
    const length = parseInt(rawLength, 10);
    return mapping ? { ...acc, [mapping[0]]: length } : acc;
  }, {});
}
function addDurationToDate(date, duration) {
  return add(date, duration);
}
function durationToMilliseconds(duration) {
  const now = /* @__PURE__ */ new Date();
  return addDurationToDate(now, duration).getTime() - now.getTime();
}
function isValidDate(dateString) {
  return !isNaN(Date.parse(dateString));
}
function isValidDuration(durationString) {
  var _a;
  for (const value of durationString.trim().split(" ")) {
    const match = value.match(/(\d+)(.+)/);
    if (match === null || match.length !== 3) {
      return false;
    }
    const key = (_a = Object.entries(durationMap).find(
      ([_, abbreviations]) => abbreviations == null ? void 0 : abbreviations.includes(match[2])
    )) == null ? void 0 : _a[0];
    if (!key) {
      return false;
    }
  }
  return true;
}
function isValidGoDuration(durationString) {
  const timeUnits = ["h", "m", "s", "ms", "us", "\xB5s", "ns"];
  for (const value of durationString.trim().split(" ")) {
    const match = value.match(/([0-9]*[.]?[0-9]+)(.+)/);
    if (match === null || match.length !== 3) {
      return false;
    }
    const isValidUnit = timeUnits.includes(match[2]);
    if (!isValidUnit) {
      return false;
    }
  }
  return true;
}

// src/types/time.ts
var DefaultTimeZone = "browser";
var TIME_FORMAT = "YYYY-MM-DD HH:mm:ss";
function getDefaultTimeRange() {
  const now = dateTime();
  return {
    from: dateTime(now).subtract(6, "hour"),
    to: now,
    raw: { from: "now-6h", to: "now" }
  };
}
function getDefaultRelativeTimeRange() {
  return {
    from: 600,
    to: 0
  };
}

// src/types/thresholds.ts
var ThresholdsMode = /* @__PURE__ */ ((ThresholdsMode2) => {
  ThresholdsMode2["Absolute"] = "absolute";
  ThresholdsMode2["Percentage"] = "percentage";
  return ThresholdsMode2;
})(ThresholdsMode || {});

// src/types/valueMapping.ts
var MappingType = /* @__PURE__ */ ((MappingType2) => {
  MappingType2["ValueToText"] = "value";
  MappingType2["RangeToText"] = "range";
  MappingType2["RegexToText"] = "regex";
  MappingType2["SpecialValue"] = "special";
  return MappingType2;
})(MappingType || {});
var SpecialValueMatch = /* @__PURE__ */ ((SpecialValueMatch2) => {
  SpecialValueMatch2["True"] = "true";
  SpecialValueMatch2["False"] = "false";
  SpecialValueMatch2["Null"] = "null";
  SpecialValueMatch2["NaN"] = "nan";
  SpecialValueMatch2["NullAndNaN"] = "null+nan";
  SpecialValueMatch2["Empty"] = "empty";
  return SpecialValueMatch2;
})(SpecialValueMatch || {});

// src/types/alerts.ts
var AlertState = /* @__PURE__ */ ((AlertState2) => {
  AlertState2["NoData"] = "no_data";
  AlertState2["Paused"] = "paused";
  AlertState2["Alerting"] = "alerting";
  AlertState2["OK"] = "ok";
  AlertState2["Pending"] = "pending";
  AlertState2["Unknown"] = "unknown";
  return AlertState2;
})(AlertState || {});

// src/text/string.ts
import { camelCase } from "lodash";
var specialChars = [
  "(",
  "[",
  "{",
  "}",
  "]",
  ")",
  "|",
  "*",
  "+",
  "-",
  ".",
  "?",
  "<",
  ">",
  "#",
  "&",
  "^",
  "$"
];
var escapeStringForRegex = (value) => {
  if (!value) {
    return value;
  }
  return specialChars.reduce(
    (escaped, currentChar) => escaped.replace(currentChar, "\\" + currentChar),
    value
  );
};
var unEscapeStringFromRegex = (value) => {
  if (!value) {
    return value;
  }
  return specialChars.reduce(
    (escaped, currentChar) => escaped.replace("\\" + currentChar, currentChar),
    value
  );
};
function stringStartsAsRegEx(str) {
  if (!str) {
    return false;
  }
  return str[0] === "/";
}
function stringToJsRegex(str) {
  if (!stringStartsAsRegEx(str)) {
    return new RegExp(`^${str}$`);
  }
  const match = str.match(new RegExp("^/(.*?)/(g?i?m?y?)$"));
  if (!match) {
    throw new Error(`'${str}' is not a valid regular expression.`);
  }
  return new RegExp(match[1], match[2]);
}
function stringToMs(str) {
  if (!str) {
    return 0;
  }
  const nr = parseInt(str, 10);
  const unit = str.substr(String(nr).length);
  const s = 1e3;
  const m = s * 60;
  const h = m * 60;
  const d = h * 24;
  switch (unit) {
    case "s":
      return nr * s;
    case "m":
      return nr * m;
    case "h":
      return nr * h;
    case "d":
      return nr * d;
    default:
      if (!unit) {
        return isNaN(nr) ? 0 : nr;
      }
      throw new Error("Not supported unit: " + unit);
  }
}
function toNumberString(value) {
  if (value !== null && value !== void 0 && Number.isFinite(value)) {
    return value.toString();
  }
  return "";
}
function toIntegerOrUndefined(value) {
  if (!value) {
    return void 0;
  }
  const v = parseInt(value, 10);
  return isNaN(v) ? void 0 : v;
}
function toFloatOrUndefined(value) {
  if (!value) {
    return void 0;
  }
  const v = parseFloat(value);
  return isNaN(v) ? void 0 : v;
}
var toPascalCase = (string) => {
  const str = camelCase(string);
  return str.charAt(0).toUpperCase() + str.substring(1);
};

// src/text/text.ts
function findHighlightChunksInText({
  searchWords,
  textToHighlight
}) {
  const chunks = [];
  for (const term of searchWords) {
    chunks.push(...findMatchesInText(textToHighlight, term));
  }
  return chunks;
}
var cleanNeedle = (needle) => {
  return needle.replace(/[[{(][\w,.-?:*+]+$/, "");
};
function findMatchesInText(haystack, needle) {
  if (!haystack || !needle) {
    return [];
  }
  const matches = [];
  const { cleaned, flags } = parseFlags(cleanNeedle(needle));
  let regexp;
  try {
    regexp = new RegExp(`(?:${cleaned})`, flags);
  } catch (error) {
    return matches;
  }
  haystack.replace(regexp, (substring, ...rest) => {
    if (substring) {
      const offset = rest[rest.length - 2];
      matches.push({
        text: substring,
        start: offset,
        length: substring.length,
        end: offset + substring.length
      });
    }
    return "";
  });
  return matches;
}
var CLEAR_FLAG = "-";
var FLAGS_REGEXP = /\(\?([ims-]+)\)/g;
function parseFlags(text) {
  const flags = /* @__PURE__ */ new Set(["g"]);
  const cleaned = text.replace(FLAGS_REGEXP, (str, group) => {
    const clearAll = group.startsWith(CLEAR_FLAG);
    for (let i = 0; i < group.length; ++i) {
      const flag = group.charAt(i);
      if (clearAll || group.charAt(i - 1) === CLEAR_FLAG) {
        flags.delete(flag);
      } else if (flag !== CLEAR_FLAG) {
        flags.add(flag);
      }
    }
    return "";
  });
  return {
    cleaned,
    flags: Array.from(flags).join("")
  };
}

// src/valueFormats/dateTimeFormatters.ts
var UNITS = [
  "year" /* Year */,
  "month" /* Month */,
  "week" /* Week */,
  "day" /* Day */,
  "hour" /* Hour */,
  "minute" /* Minute */,
  "second" /* Second */,
  "millisecond" /* Millisecond */
];
var INTERVALS_IN_SECONDS = {
  ["year" /* Year */]: 31536e3,
  ["month" /* Month */]: 2592e3,
  ["week" /* Week */]: 604800,
  ["day" /* Day */]: 86400,
  ["hour" /* Hour */]: 3600,
  ["minute" /* Minute */]: 60,
  ["second" /* Second */]: 1,
  ["millisecond" /* Millisecond */]: 1e-3
};
function toNanoSeconds(size, decimals) {
  if (size === null) {
    return { text: "" };
  }
  if (Math.abs(size) < 1e3) {
    return { text: toFixed(size, decimals), suffix: " ns" };
  } else if (Math.abs(size) < 1e6) {
    return toFixedScaled(size / 1e3, decimals, " \xB5s");
  } else if (Math.abs(size) < 1e9) {
    return toFixedScaled(size / 1e6, decimals, " ms");
  } else if (Math.abs(size) < 6e10) {
    return toFixedScaled(size / 1e9, decimals, " s");
  } else if (Math.abs(size) < 36e11) {
    return toFixedScaled(size / 6e10, decimals, " min");
  } else if (Math.abs(size) < 864e11) {
    return toFixedScaled(size / 36e11, decimals, " hour");
  } else {
    return toFixedScaled(size / 864e11, decimals, " day");
  }
}
function toMicroSeconds(size, decimals) {
  if (size === null) {
    return { text: "" };
  }
  if (Math.abs(size) < 1e3) {
    return { text: toFixed(size, decimals), suffix: " \xB5s" };
  } else if (Math.abs(size) < 1e6) {
    return toFixedScaled(size / 1e3, decimals, " ms");
  } else {
    return toFixedScaled(size / 1e6, decimals, " s");
  }
}
function toMilliSeconds(size, decimals, scaledDecimals) {
  if (size === null) {
    return { text: "" };
  }
  if (Math.abs(size) < 1e3) {
    return { text: toFixed(size, decimals), suffix: " ms" };
  } else if (Math.abs(size) < 6e4) {
    return toFixedScaled(size / 1e3, decimals, " s");
  } else if (Math.abs(size) < 36e5) {
    return toFixedScaled(size / 6e4, decimals, " min");
  } else if (Math.abs(size) < 864e5) {
    return toFixedScaled(size / 36e5, decimals, " hour");
  } else if (Math.abs(size) < 31536e6) {
    return toFixedScaled(size / 864e5, decimals, " day");
  }
  return toFixedScaled(size / 31536e6, decimals, " year");
}
function toSeconds(size, decimals) {
  if (size === null) {
    return { text: "" };
  }
  if (size === 0) {
    return { text: "0", suffix: " s" };
  }
  if (Math.abs(size) < 1e-6) {
    return toFixedScaled(size * 1e9, decimals, " ns");
  }
  if (Math.abs(size) < 1e-3) {
    return toFixedScaled(size * 1e6, decimals, " \xB5s");
  }
  if (Math.abs(size) < 1) {
    return toFixedScaled(size * 1e3, decimals, " ms");
  }
  if (Math.abs(size) < 60) {
    return { text: toFixed(size, decimals), suffix: " s" };
  } else if (Math.abs(size) < 3600) {
    return toFixedScaled(size / 60, decimals, " min");
  } else if (Math.abs(size) < 86400) {
    return toFixedScaled(size / 3600, decimals, " hour");
  } else if (Math.abs(size) < 604800) {
    return toFixedScaled(size / 86400, decimals, " day");
  } else if (Math.abs(size) < 31536e3) {
    return toFixedScaled(size / 604800, decimals, " week");
  }
  return toFixedScaled(size / 31556900, decimals, " year");
}
function toMinutes(size, decimals) {
  if (size === null) {
    return { text: "" };
  }
  if (Math.abs(size) < 60) {
    return { text: toFixed(size, decimals), suffix: " min" };
  } else if (Math.abs(size) < 1440) {
    return toFixedScaled(size / 60, decimals, " hour");
  } else if (Math.abs(size) < 10080) {
    return toFixedScaled(size / 1440, decimals, " day");
  } else if (Math.abs(size) < 604800) {
    return toFixedScaled(size / 10080, decimals, " week");
  } else {
    return toFixedScaled(size / 525948, decimals, " year");
  }
}
function toHours(size, decimals) {
  if (size === null) {
    return { text: "" };
  }
  if (Math.abs(size) < 24) {
    return { text: toFixed(size, decimals), suffix: " hour" };
  } else if (Math.abs(size) < 168) {
    return toFixedScaled(size / 24, decimals, " day");
  } else if (Math.abs(size) < 8760) {
    return toFixedScaled(size / 168, decimals, " week");
  } else {
    return toFixedScaled(size / 8760, decimals, " year");
  }
}
function toDays(size, decimals) {
  if (size === null) {
    return { text: "" };
  }
  if (Math.abs(size) < 7) {
    return { text: toFixed(size, decimals), suffix: " day" };
  } else if (Math.abs(size) < 365) {
    return toFixedScaled(size / 7, decimals, " week");
  } else {
    return toFixedScaled(size / 365, decimals, " year");
  }
}
function toDuration2(size, decimals, timeScale) {
  if (size === null) {
    return { text: "" };
  }
  if (size === 0) {
    return { text: "0", suffix: " " + timeScale + "s" };
  }
  if (size < 0) {
    const v = toDuration2(-size, decimals, timeScale);
    if (!v.suffix) {
      v.suffix = "";
    }
    v.suffix += " ago";
    return v;
  }
  size *= INTERVALS_IN_SECONDS[timeScale] * 1e3;
  const strings = [];
  let decrementDecimals = false;
  let decimalsCount = 0;
  if (decimals !== null && decimals !== void 0) {
    decimalsCount = decimals;
  }
  for (let i = 0; i < UNITS.length && decimalsCount >= 0; i++) {
    const interval = INTERVALS_IN_SECONDS[UNITS[i]] * 1e3;
    const value = size / interval;
    if (value >= 1 || decrementDecimals) {
      decrementDecimals = true;
      const floor = Math.floor(value);
      const unit = UNITS[i] + (floor !== 1 ? "s" : "");
      strings.push(floor + " " + unit);
      size = size % interval;
      decimalsCount--;
    }
  }
  return { text: strings.join(", ") };
}
function toClock(size, decimals) {
  if (size === null) {
    return { text: "" };
  }
  if (size < 1e3) {
    return {
      text: toUtc(size).format("SSS\\m\\s")
    };
  }
  if (size < 6e4) {
    let format2 = "ss\\s:SSS\\m\\s";
    if (decimals === 0) {
      format2 = "ss\\s";
    }
    return { text: toUtc(size).format(format2) };
  }
  if (size < 36e5) {
    let format2 = "mm\\m:ss\\s:SSS\\m\\s";
    if (decimals === 0) {
      format2 = "mm\\m";
    } else if (decimals === 1) {
      format2 = "mm\\m:ss\\s";
    }
    return { text: toUtc(size).format(format2) };
  }
  let format = "mm\\m:ss\\s:SSS\\m\\s";
  const hours = `${("0" + Math.floor(toDuration(size, "milliseconds").asHours())).slice(-2)}h`;
  if (decimals === 0) {
    format = "";
  } else if (decimals === 1) {
    format = "mm\\m";
  } else if (decimals === 2) {
    format = "mm\\m:ss\\s";
  }
  const text = format ? `${hours}:${toUtc(size).format(format)}` : hours;
  return { text };
}
function toDurationInMilliseconds(size, decimals) {
  return toDuration2(size, decimals, "millisecond" /* Millisecond */);
}
function toDurationInSeconds(size, decimals) {
  return toDuration2(size, decimals, "second" /* Second */);
}
function toDurationInHoursMinutesSeconds(size) {
  if (size < 0) {
    const v = toDurationInHoursMinutesSeconds(-size);
    if (!v.suffix) {
      v.suffix = "";
    }
    v.suffix += " ago";
    return v;
  }
  const strings = [];
  const numHours = Math.floor(size / 3600);
  const numMinutes = Math.floor(size % 3600 / 60);
  const numSeconds = Math.floor(size % 3600 % 60);
  numHours > 9 ? strings.push("" + numHours) : strings.push("0" + numHours);
  numMinutes > 9 ? strings.push("" + numMinutes) : strings.push("0" + numMinutes);
  numSeconds > 9 ? strings.push("" + numSeconds) : strings.push("0" + numSeconds);
  return { text: strings.join(":") };
}
function toDurationInDaysHoursMinutesSeconds(size) {
  if (size < 0) {
    const v = toDurationInDaysHoursMinutesSeconds(-size);
    if (!v.suffix) {
      v.suffix = "";
    }
    v.suffix += " ago";
    return v;
  }
  let dayString = "";
  const numDays = Math.floor(size / (24 * 3600));
  if (numDays > 0) {
    dayString = numDays + " d ";
  }
  const hmsString = toDurationInHoursMinutesSeconds(size - numDays * 24 * 3600);
  return { text: dayString + hmsString.text };
}
function toTimeTicks(size, decimals) {
  return toSeconds(size / 100, decimals);
}
function toClockMilliseconds(size, decimals) {
  return toClock(size, decimals);
}
function toClockSeconds(size, decimals) {
  return toClock(size * 1e3, decimals);
}
function toDateTimeValueFormatter(pattern, todayPattern) {
  return (value, decimals, scaledDecimals, timeZone) => {
    if (todayPattern) {
      if (dateTime().isSame(value, "day")) {
        return {
          text: dateTimeFormat(value, { format: todayPattern, timeZone })
        };
      }
    }
    return { text: dateTimeFormat(value, { format: pattern, timeZone }) };
  };
}
var dateTimeAsIso = toDateTimeValueFormatter("YYYY-MM-DD HH:mm:ss");
var dateTimeAsIsoNoDateIfToday = toDateTimeValueFormatter(
  "YYYY-MM-DD HH:mm:ss",
  "HH:mm:ss"
);
var dateTimeAsUS = toDateTimeValueFormatter("MM/DD/YYYY h:mm:ss a");
var dateTimeAsUSNoDateIfToday = toDateTimeValueFormatter(
  "MM/DD/YYYY h:mm:ss a",
  "h:mm:ss a"
);
function getDateTimeAsLocalFormat() {
  return toDateTimeValueFormatter(
    localTimeFormat({
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit"
    })
  );
}
function getDateTimeAsLocalFormatNoDateIfToday() {
  return toDateTimeValueFormatter(
    localTimeFormat({
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit"
    }),
    localTimeFormat({
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit"
    })
  );
}
function dateTimeSystemFormatter(value, decimals, scaledDecimals, timeZone, showMs) {
  return {
    text: dateTimeFormat(value, {
      format: showMs ? systemDateFormats.getFullDateMS() : systemDateFormats.fullDate,
      timeZone
    })
  };
}
function dateTimeFromNow(value, decimals, scaledDecimals, timeZone) {
  return { text: dateTimeFormatTimeAgo(value, { timeZone }) };
}

// src/valueFormats/arithmeticFormatters.ts
function toPercent(size, decimals) {
  if (size === null) {
    return { text: "" };
  }
  return { text: toFixed(size, decimals), suffix: "%" };
}
function toPercentUnit(size, decimals) {
  if (size === null) {
    return { text: "" };
  }
  return { text: toFixed(100 * size, decimals), suffix: "%" };
}
function toHex0x(value, decimals) {
  if (value == null) {
    return { text: "" };
  }
  const asHex = toHex(value, decimals);
  if (asHex.text.substring(0, 1) === "-") {
    asHex.text = "-0x" + asHex.text.substring(1);
  } else {
    asHex.text = "0x" + asHex.text;
  }
  return asHex;
}
function toHex(value, decimals) {
  if (value == null) {
    return { text: "" };
  }
  return {
    text: parseFloat(toFixed(value, decimals)).toString(16).toUpperCase()
  };
}
function sci(value, decimals) {
  if (value == null) {
    return { text: "" };
  }
  return { text: value.toExponential(decimals) };
}

// src/valueFormats/symbolFormatters.ts
function currency(symbol, asSuffix) {
  const units2 = ["", "K", "M", "B", "T"];
  const scaler = scaledUnits(1e3, units2);
  return (size, decimals, scaledDecimals) => {
    if (size === null) {
      return { text: "" };
    }
    const scaled = scaler(size, decimals, scaledDecimals);
    if (asSuffix) {
      scaled.suffix = scaled.suffix !== void 0 ? `${scaled.suffix}${symbol}` : void 0;
    } else {
      scaled.prefix = symbol;
    }
    return scaled;
  };
}
function getOffsetFromSIPrefix(c) {
  switch (c) {
    case "f":
      return -5;
    case "p":
      return -4;
    case "n":
      return -3;
    case "\u03BC":
    case "\xB5":
      return -2;
    case "m":
      return -1;
    case "":
      return 0;
    case "k":
      return 1;
    case "M":
      return 2;
    case "G":
      return 3;
    case "T":
      return 4;
    case "P":
      return 5;
    case "E":
      return 6;
    case "Z":
      return 7;
    case "Y":
      return 8;
  }
  return 0;
}
function binaryPrefix(unit, offset = 0) {
  const prefixes = ["", "Ki", "Mi", "Gi", "Ti", "Pi", "Ei", "Zi", "Yi"].slice(
    offset
  );
  const units2 = prefixes.map((p) => {
    return " " + p + unit;
  });
  return scaledUnits(1024, units2);
}
function SIPrefix(unit, offset = 0, sizeFactor = 1) {
  let prefixes = [
    "f",
    "p",
    "n",
    "\xB5",
    "m",
    "",
    "k",
    "M",
    "G",
    "T",
    "P",
    "E",
    "Z",
    "Y"
  ];
  prefixes = prefixes.slice(5 + (offset || 0));
  const units2 = prefixes.map((p) => {
    return " " + p + unit;
  });
  return scaledUnits(1e3, units2, sizeFactor);
}

// src/valueFormats/categories.ts
var getCategories = () => [
  {
    name: "Misc",
    formats: [
      { name: "none", id: "none", fn: toFixedUnit("") },
      { name: "String", id: "string", fn: stringFormater },
      {
        name: "short",
        id: "short",
        fn: scaledUnits(1e3, [
          "",
          " K",
          " Mil",
          " Bil",
          " Tri",
          " Quadr",
          " Quint",
          " Sext",
          " Sept"
        ])
      },
      { name: "Percent (0-100)", id: "percent", fn: toPercent },
      { name: "Percent (0.0-1.0)", id: "percentunit", fn: toPercentUnit },
      { name: "Humidity (%H)", id: "humidity", fn: toFixedUnit("%H") },
      { name: "Decibel", id: "dB", fn: toFixedUnit("dB") },
      { name: "Hexadecimal (0x)", id: "hex0x", fn: toHex0x },
      { name: "Hexadecimal", id: "hex", fn: toHex },
      { name: "Scientific notation", id: "sci", fn: sci },
      { name: "Locale format", id: "locale", fn: locale },
      { name: "Pixels", id: "pixel", fn: toFixedUnit("px") }
    ]
  },
  {
    name: "Acceleration",
    formats: [
      { name: "Meters/sec\xB2", id: "accMS2", fn: toFixedUnit("m/sec\xB2") },
      { name: "Feet/sec\xB2", id: "accFS2", fn: toFixedUnit("f/sec\xB2") },
      { name: "G unit", id: "accG", fn: toFixedUnit("g") }
    ]
  },
  {
    name: "Angle",
    formats: [
      { name: "Degrees (\xB0)", id: "degree", fn: toFixedUnit("\xB0") },
      { name: "Radians", id: "radian", fn: toFixedUnit("rad") },
      { name: "Gradian", id: "grad", fn: toFixedUnit("grad") },
      { name: "Arc Minutes", id: "arcmin", fn: toFixedUnit("arcmin") },
      { name: "Arc Seconds", id: "arcsec", fn: toFixedUnit("arcsec") }
    ]
  },
  {
    name: "Area",
    formats: [
      { name: "Square Meters (m\xB2)", id: "areaM2", fn: toFixedUnit("m\xB2") },
      { name: "Square Feet (ft\xB2)", id: "areaF2", fn: toFixedUnit("ft\xB2") },
      { name: "Square Miles (mi\xB2)", id: "areaMI2", fn: toFixedUnit("mi\xB2") }
    ]
  },
  {
    name: "Computation",
    formats: [
      { name: "FLOP/s", id: "flops", fn: SIPrefix("FLOPS") },
      { name: "MFLOP/s", id: "mflops", fn: SIPrefix("FLOPS", 2) },
      { name: "GFLOP/s", id: "gflops", fn: SIPrefix("FLOPS", 3) },
      { name: "TFLOP/s", id: "tflops", fn: SIPrefix("FLOPS", 4) },
      { name: "PFLOP/s", id: "pflops", fn: SIPrefix("FLOPS", 5) },
      { name: "EFLOP/s", id: "eflops", fn: SIPrefix("FLOPS", 6) },
      { name: "ZFLOP/s", id: "zflops", fn: SIPrefix("FLOPS", 7) },
      { name: "YFLOP/s", id: "yflops", fn: SIPrefix("FLOPS", 8) }
    ]
  },
  {
    name: "Concentration",
    formats: [
      { name: "parts-per-million (ppm)", id: "ppm", fn: toFixedUnit("ppm") },
      { name: "parts-per-billion (ppb)", id: "conppb", fn: toFixedUnit("ppb") },
      {
        name: "nanogram per cubic meter (ng/m\xB3)",
        id: "conngm3",
        fn: toFixedUnit("ng/m\xB3")
      },
      {
        name: "nanogram per normal cubic meter (ng/Nm\xB3)",
        id: "conngNm3",
        fn: toFixedUnit("ng/Nm\xB3")
      },
      {
        name: "microgram per cubic meter (\u03BCg/m\xB3)",
        id: "con\u03BCgm3",
        fn: toFixedUnit("\u03BCg/m\xB3")
      },
      {
        name: "microgram per normal cubic meter (\u03BCg/Nm\xB3)",
        id: "con\u03BCgNm3",
        fn: toFixedUnit("\u03BCg/Nm\xB3")
      },
      {
        name: "milligram per cubic meter (mg/m\xB3)",
        id: "conmgm3",
        fn: toFixedUnit("mg/m\xB3")
      },
      {
        name: "milligram per normal cubic meter (mg/Nm\xB3)",
        id: "conmgNm3",
        fn: toFixedUnit("mg/Nm\xB3")
      },
      {
        name: "gram per cubic meter (g/m\xB3)",
        id: "congm3",
        fn: toFixedUnit("g/m\xB3")
      },
      {
        name: "gram per normal cubic meter (g/Nm\xB3)",
        id: "congNm3",
        fn: toFixedUnit("g/Nm\xB3")
      },
      {
        name: "milligrams per decilitre (mg/dL)",
        id: "conmgdL",
        fn: toFixedUnit("mg/dL")
      },
      {
        name: "millimoles per litre (mmol/L)",
        id: "conmmolL",
        fn: toFixedUnit("mmol/L")
      }
    ]
  },
  {
    name: "Currency",
    formats: [
      { name: "Dollars ($)", id: "currencyUSD", fn: currency("$") },
      { name: "Pounds (\xA3)", id: "currencyGBP", fn: currency("\xA3") },
      { name: "Euro (\u20AC)", id: "currencyEUR", fn: currency("\u20AC") },
      { name: "Yen (\xA5)", id: "currencyJPY", fn: currency("\xA5") },
      { name: "Rubles (\u20BD)", id: "currencyRUB", fn: currency("\u20BD") },
      { name: "Hryvnias (\u20B4)", id: "currencyUAH", fn: currency("\u20B4") },
      { name: "Real (R$)", id: "currencyBRL", fn: currency("R$") },
      {
        name: "Danish Krone (kr)",
        id: "currencyDKK",
        fn: currency("kr", true)
      },
      {
        name: "Icelandic Kr\xF3na (kr)",
        id: "currencyISK",
        fn: currency("kr", true)
      },
      {
        name: "Norwegian Krone (kr)",
        id: "currencyNOK",
        fn: currency("kr", true)
      },
      {
        name: "Swedish Krona (kr)",
        id: "currencySEK",
        fn: currency("kr", true)
      },
      { name: "Czech koruna (czk)", id: "currencyCZK", fn: currency("czk") },
      { name: "Swiss franc (CHF)", id: "currencyCHF", fn: currency("CHF") },
      { name: "Polish Z\u0142oty (PLN)", id: "currencyPLN", fn: currency("PLN") },
      { name: "Bitcoin (\u0E3F)", id: "currencyBTC", fn: currency("\u0E3F") },
      { name: "Milli Bitcoin (\u0E3F)", id: "currencymBTC", fn: currency("mBTC") },
      { name: "Micro Bitcoin (\u0E3F)", id: "currency\u03BCBTC", fn: currency("\u03BCBTC") },
      { name: "South African Rand (R)", id: "currencyZAR", fn: currency("R") },
      { name: "Indian Rupee (\u20B9)", id: "currencyINR", fn: currency("\u20B9") },
      { name: "South Korean Won (\u20A9)", id: "currencyKRW", fn: currency("\u20A9") },
      { name: "Indonesian Rupiah (Rp)", id: "currencyIDR", fn: currency("Rp") },
      { name: "Philippine Peso (PHP)", id: "currencyPHP", fn: currency("PHP") },
      {
        name: "Vietnamese Dong (VND)",
        id: "currencyVND",
        fn: currency("\u0111", true)
      }
    ]
  },
  {
    name: "Data",
    formats: [
      { name: "bytes(IEC)", id: "bytes", fn: binaryPrefix("B") },
      { name: "bytes(SI)", id: "decbytes", fn: SIPrefix("B") },
      { name: "bits(IEC)", id: "bits", fn: binaryPrefix("b") },
      { name: "bits(SI)", id: "decbits", fn: SIPrefix("b") },
      { name: "kibibytes", id: "kbytes", fn: binaryPrefix("B", 1) },
      { name: "kilobytes", id: "deckbytes", fn: SIPrefix("B", 1) },
      { name: "mebibytes", id: "mbytes", fn: binaryPrefix("B", 2) },
      { name: "megabytes", id: "decmbytes", fn: SIPrefix("B", 2) },
      { name: "gibibytes", id: "gbytes", fn: binaryPrefix("B", 3) },
      { name: "gigabytes", id: "decgbytes", fn: SIPrefix("B", 3) },
      { name: "tebibytes", id: "tbytes", fn: binaryPrefix("B", 4) },
      { name: "terabytes", id: "dectbytes", fn: SIPrefix("B", 4) },
      { name: "pebibytes", id: "pbytes", fn: binaryPrefix("B", 5) },
      { name: "petabytes", id: "decpbytes", fn: SIPrefix("B", 5) }
    ]
  },
  {
    name: "Data rate",
    formats: [
      { name: "packets/sec", id: "pps", fn: SIPrefix("p/s") },
      { name: "bytes/sec(IEC)", id: "binBps", fn: binaryPrefix("B/s") },
      { name: "bytes/sec(SI)", id: "Bps", fn: SIPrefix("B/s") },
      { name: "bits/sec(IEC)", id: "binbps", fn: binaryPrefix("b/s") },
      { name: "bits/sec(SI)", id: "bps", fn: SIPrefix("b/s") },
      { name: "kibibytes/sec", id: "KiBs", fn: binaryPrefix("B/s", 1) },
      { name: "kibibits/sec", id: "Kibits", fn: binaryPrefix("b/s", 1) },
      { name: "kilobytes/sec", id: "KBs", fn: SIPrefix("B/s", 1) },
      { name: "kilobits/sec", id: "Kbits", fn: SIPrefix("b/s", 1) },
      { name: "mebibytes/sec", id: "MiBs", fn: binaryPrefix("B/s", 2) },
      { name: "mebibits/sec", id: "Mibits", fn: binaryPrefix("b/s", 2) },
      { name: "megabytes/sec", id: "MBs", fn: SIPrefix("B/s", 2) },
      { name: "megabits/sec", id: "Mbits", fn: SIPrefix("b/s", 2) },
      { name: "gibibytes/sec", id: "GiBs", fn: binaryPrefix("B/s", 3) },
      { name: "gibibits/sec", id: "Gibits", fn: binaryPrefix("b/s", 3) },
      { name: "gigabytes/sec", id: "GBs", fn: SIPrefix("B/s", 3) },
      { name: "gigabits/sec", id: "Gbits", fn: SIPrefix("b/s", 3) },
      { name: "tebibytes/sec", id: "TiBs", fn: binaryPrefix("B/s", 4) },
      { name: "tebibits/sec", id: "Tibits", fn: binaryPrefix("b/s", 4) },
      { name: "terabytes/sec", id: "TBs", fn: SIPrefix("B/s", 4) },
      { name: "terabits/sec", id: "Tbits", fn: SIPrefix("b/s", 4) },
      { name: "pebibytes/sec", id: "PiBs", fn: binaryPrefix("B/s", 5) },
      { name: "pebibits/sec", id: "Pibits", fn: binaryPrefix("b/s", 5) },
      { name: "petabytes/sec", id: "PBs", fn: SIPrefix("B/s", 5) },
      { name: "petabits/sec", id: "Pbits", fn: SIPrefix("b/s", 5) }
    ]
  },
  {
    name: "Date & time",
    formats: [
      { name: "Datetime ISO", id: "dateTimeAsIso", fn: dateTimeAsIso },
      {
        name: "Datetime ISO (No date if today)",
        id: "dateTimeAsIsoNoDateIfToday",
        fn: dateTimeAsIsoNoDateIfToday
      },
      { name: "Datetime US", id: "dateTimeAsUS", fn: dateTimeAsUS },
      {
        name: "Datetime US (No date if today)",
        id: "dateTimeAsUSNoDateIfToday",
        fn: dateTimeAsUSNoDateIfToday
      },
      {
        name: "Datetime local",
        id: "dateTimeAsLocal",
        fn: getDateTimeAsLocalFormat()
      },
      {
        name: "Datetime local (No date if today)",
        id: "dateTimeAsLocalNoDateIfToday",
        fn: getDateTimeAsLocalFormatNoDateIfToday()
      },
      {
        name: "Datetime default",
        id: "dateTimeAsSystem",
        fn: dateTimeSystemFormatter
      },
      { name: "From Now", id: "dateTimeFromNow", fn: dateTimeFromNow }
    ]
  },
  {
    name: "Energy",
    formats: [
      { name: "Watt (W)", id: "watt", fn: SIPrefix("W") },
      { name: "Kilowatt (kW)", id: "kwatt", fn: SIPrefix("W", 1) },
      { name: "Megawatt (MW)", id: "megwatt", fn: SIPrefix("W", 2) },
      { name: "Gigawatt (GW)", id: "gwatt", fn: SIPrefix("W", 3) },
      { name: "Milliwatt (mW)", id: "mwatt", fn: SIPrefix("W", -1) },
      {
        name: "Watt per square meter (W/m\xB2)",
        id: "Wm2",
        fn: toFixedUnit("W/m\xB2")
      },
      { name: "Volt-Ampere (VA)", id: "voltamp", fn: SIPrefix("VA") },
      { name: "Kilovolt-Ampere (kVA)", id: "kvoltamp", fn: SIPrefix("VA", 1) },
      {
        name: "Volt-Ampere reactive (var)",
        id: "voltampreact",
        fn: SIPrefix("var")
      },
      {
        name: "Volt-Ampere reactive (varr)",
        id: "voltampreacth",
        fn: SIPrefix("varh")
      },
      {
        name: "Kilovolt-Ampere reactive (kvar)",
        id: "kvoltampreact",
        fn: SIPrefix("var", 1)
      },
      {
        name: "Volt-Ampere-reactive-hour (kvarh)",
        id: "kvoltampreacth",
        fn: SIPrefix("varh", 1)
      },
      {
        name: "Volt-Ampere-reactive-second (vars)",
        id: "voltampreacts",
        fn: SIPrefix("varh", 0, 1 / 3600)
      },
      { name: "Watt-hour (Wh)", id: "watth", fn: SIPrefix("Wh") },
      {
        name: "Watt-hour per Kilogram (Wh/kg)",
        id: "watthperkg",
        fn: SIPrefix("Wh/kg")
      },
      {
        name: "Watt-second (Ws)",
        id: "watts",
        fn: SIPrefix("Wh", 0, 1 / 3600)
      },
      { name: "Kilowatt-hour (kWh)", id: "kwatth", fn: SIPrefix("Wh", 1) },
      { name: "Kilowatt-min (kWm)", id: "kwattm", fn: SIPrefix("W-Min", 1) },
      { name: "Ampere-hour (Ah)", id: "amph", fn: SIPrefix("Ah") },
      { name: "Kiloampere-hour (kAh)", id: "kamph", fn: SIPrefix("Ah", 1) },
      { name: "Milliampere-hour (mAh)", id: "mamph", fn: SIPrefix("Ah", -1) },
      { name: "Joule (J)", id: "joule", fn: SIPrefix("J") },
      { name: "Electron volt (eV)", id: "ev", fn: SIPrefix("eV") },
      { name: "Ampere (A)", id: "amp", fn: SIPrefix("A") },
      { name: "Kiloampere (kA)", id: "kamp", fn: SIPrefix("A", 1) },
      { name: "Milliampere (mA)", id: "mamp", fn: SIPrefix("A", -1) },
      { name: "Volt (V)", id: "volt", fn: SIPrefix("V") },
      { name: "Kilovolt (kV)", id: "kvolt", fn: SIPrefix("V", 1) },
      { name: "Millivolt (mV)", id: "mvolt", fn: SIPrefix("V", -1) },
      { name: "Decibel-milliwatt (dBm)", id: "dBm", fn: SIPrefix("dBm") },
      { name: "Ohm (\u03A9)", id: "ohm", fn: SIPrefix("\u03A9") },
      { name: "Kiloohm (k\u03A9)", id: "kohm", fn: SIPrefix("\u03A9", 1) },
      { name: "Megaohm (M\u03A9)", id: "Mohm", fn: SIPrefix("\u03A9", 2) },
      { name: "Farad (F)", id: "farad", fn: SIPrefix("F") },
      { name: "Microfarad (\xB5F)", id: "\xB5farad", fn: SIPrefix("F", -2) },
      { name: "Nanofarad (nF)", id: "nfarad", fn: SIPrefix("F", -3) },
      { name: "Picofarad (pF)", id: "pfarad", fn: SIPrefix("F", -4) },
      { name: "Femtofarad (fF)", id: "ffarad", fn: SIPrefix("F", -5) },
      { name: "Henry (H)", id: "henry", fn: SIPrefix("H") },
      { name: "Millihenry (mH)", id: "mhenry", fn: SIPrefix("H", -1) },
      { name: "Microhenry (\xB5H)", id: "\xB5henry", fn: SIPrefix("H", -2) },
      { name: "Lumens (Lm)", id: "lumens", fn: SIPrefix("Lm") }
    ]
  },
  {
    name: "Flow",
    formats: [
      { name: "Gallons/min (gpm)", id: "flowgpm", fn: toFixedUnit("gpm") },
      { name: "Cubic meters/sec (cms)", id: "flowcms", fn: toFixedUnit("cms") },
      { name: "Cubic feet/sec (cfs)", id: "flowcfs", fn: toFixedUnit("cfs") },
      { name: "Cubic feet/min (cfm)", id: "flowcfm", fn: toFixedUnit("cfm") },
      { name: "Litre/hour", id: "litreh", fn: toFixedUnit("L/h") },
      { name: "Litre/min (L/min)", id: "flowlpm", fn: toFixedUnit("L/min") },
      {
        name: "milliLitre/min (mL/min)",
        id: "flowmlpm",
        fn: toFixedUnit("mL/min")
      },
      { name: "Lux (lx)", id: "lux", fn: toFixedUnit("lux") }
    ]
  },
  {
    name: "Force",
    formats: [
      { name: "Newton-meters (Nm)", id: "forceNm", fn: SIPrefix("Nm") },
      {
        name: "Kilonewton-meters (kNm)",
        id: "forcekNm",
        fn: SIPrefix("Nm", 1)
      },
      { name: "Newtons (N)", id: "forceN", fn: SIPrefix("N") },
      { name: "Kilonewtons (kN)", id: "forcekN", fn: SIPrefix("N", 1) }
    ]
  },
  {
    name: "Hash rate",
    formats: [
      { name: "hashes/sec", id: "Hs", fn: SIPrefix("H/s") },
      { name: "kilohashes/sec", id: "KHs", fn: SIPrefix("H/s", 1) },
      { name: "megahashes/sec", id: "MHs", fn: SIPrefix("H/s", 2) },
      { name: "gigahashes/sec", id: "GHs", fn: SIPrefix("H/s", 3) },
      { name: "terahashes/sec", id: "THs", fn: SIPrefix("H/s", 4) },
      { name: "petahashes/sec", id: "PHs", fn: SIPrefix("H/s", 5) },
      { name: "exahashes/sec", id: "EHs", fn: SIPrefix("H/s", 6) }
    ]
  },
  {
    name: "Mass",
    formats: [
      { name: "milligram (mg)", id: "massmg", fn: SIPrefix("g", -1) },
      { name: "gram (g)", id: "massg", fn: SIPrefix("g") },
      { name: "pound (lb)", id: "masslb", fn: toFixedUnit("lb") },
      { name: "kilogram (kg)", id: "masskg", fn: SIPrefix("g", 1) },
      { name: "metric ton (t)", id: "masst", fn: toFixedUnit("t") }
    ]
  },
  {
    name: "Length",
    formats: [
      { name: "millimeter (mm)", id: "lengthmm", fn: SIPrefix("m", -1) },
      { name: "inch (in)", id: "lengthin", fn: toFixedUnit("in") },
      { name: "feet (ft)", id: "lengthft", fn: toFixedUnit("ft") },
      { name: "meter (m)", id: "lengthm", fn: SIPrefix("m") },
      { name: "kilometer (km)", id: "lengthkm", fn: SIPrefix("m", 1) },
      { name: "mile (mi)", id: "lengthmi", fn: toFixedUnit("mi") }
    ]
  },
  {
    name: "Pressure",
    formats: [
      { name: "Millibars", id: "pressurembar", fn: SIPrefix("bar", -1) },
      { name: "Bars", id: "pressurebar", fn: SIPrefix("bar") },
      { name: "Kilobars", id: "pressurekbar", fn: SIPrefix("bar", 1) },
      { name: "Pascals", id: "pressurepa", fn: SIPrefix("Pa") },
      { name: "Hectopascals", id: "pressurehpa", fn: toFixedUnit("hPa") },
      { name: "Kilopascals", id: "pressurekpa", fn: toFixedUnit("kPa") },
      { name: "Inches of mercury", id: "pressurehg", fn: toFixedUnit('"Hg') },
      {
        name: "PSI",
        id: "pressurepsi",
        fn: scaledUnits(1e3, ["psi", "ksi", "Mpsi"])
      }
    ]
  },
  {
    name: "Radiation",
    formats: [
      { name: "Becquerel (Bq)", id: "radbq", fn: SIPrefix("Bq") },
      { name: "curie (Ci)", id: "radci", fn: SIPrefix("Ci") },
      { name: "Gray (Gy)", id: "radgy", fn: SIPrefix("Gy") },
      { name: "rad", id: "radrad", fn: SIPrefix("rad") },
      { name: "Sievert (Sv)", id: "radsv", fn: SIPrefix("Sv") },
      { name: "milliSievert (mSv)", id: "radmsv", fn: SIPrefix("Sv", -1) },
      { name: "microSievert (\xB5Sv)", id: "radusv", fn: SIPrefix("Sv", -2) },
      { name: "rem", id: "radrem", fn: SIPrefix("rem") },
      { name: "Exposure (C/kg)", id: "radexpckg", fn: SIPrefix("C/kg") },
      { name: "roentgen (R)", id: "radr", fn: SIPrefix("R") },
      { name: "Sievert/hour (Sv/h)", id: "radsvh", fn: SIPrefix("Sv/h") },
      {
        name: "milliSievert/hour (mSv/h)",
        id: "radmsvh",
        fn: SIPrefix("Sv/h", -1)
      },
      {
        name: "microSievert/hour (\xB5Sv/h)",
        id: "radusvh",
        fn: SIPrefix("Sv/h", -2)
      }
    ]
  },
  {
    name: "Rotational Speed",
    formats: [
      {
        name: "Revolutions per minute (rpm)",
        id: "rotrpm",
        fn: toFixedUnit("rpm")
      },
      { name: "Hertz (Hz)", id: "rothz", fn: SIPrefix("Hz") },
      {
        name: "Radians per second (rad/s)",
        id: "rotrads",
        fn: toFixedUnit("rad/s")
      },
      {
        name: "Degrees per second (\xB0/s)",
        id: "rotdegs",
        fn: toFixedUnit("\xB0/s")
      }
    ]
  },
  {
    name: "Temperature",
    formats: [
      { name: "Celsius (\xB0C)", id: "celsius", fn: toFixedUnit("\xB0C") },
      { name: "Fahrenheit (\xB0F)", id: "fahrenheit", fn: toFixedUnit("\xB0F") },
      { name: "Kelvin (K)", id: "kelvin", fn: toFixedUnit("K") }
    ]
  },
  {
    name: "Time",
    formats: [
      { name: "Hertz (1/s)", id: "hertz", fn: SIPrefix("Hz") },
      { name: "nanoseconds (ns)", id: "ns", fn: toNanoSeconds },
      { name: "microseconds (\xB5s)", id: "\xB5s", fn: toMicroSeconds },
      { name: "milliseconds (ms)", id: "ms", fn: toMilliSeconds },
      { name: "seconds (s)", id: "s", fn: toSeconds },
      { name: "minutes (m)", id: "m", fn: toMinutes },
      { name: "hours (h)", id: "h", fn: toHours },
      { name: "days (d)", id: "d", fn: toDays },
      {
        name: "duration (ms)",
        id: "dtdurationms",
        fn: toDurationInMilliseconds
      },
      { name: "duration (s)", id: "dtdurations", fn: toDurationInSeconds },
      {
        name: "duration (hh:mm:ss)",
        id: "dthms",
        fn: toDurationInHoursMinutesSeconds
      },
      {
        name: "duration (d hh:mm:ss)",
        id: "dtdhms",
        fn: toDurationInDaysHoursMinutesSeconds
      },
      { name: "Timeticks (s/100)", id: "timeticks", fn: toTimeTicks },
      { name: "clock (ms)", id: "clockms", fn: toClockMilliseconds },
      { name: "clock (s)", id: "clocks", fn: toClockSeconds }
    ]
  },
  {
    name: "Throughput",
    formats: [
      { name: "counts/sec (cps)", id: "cps", fn: simpleCountUnit("c/s") },
      { name: "ops/sec (ops)", id: "ops", fn: simpleCountUnit("ops/s") },
      { name: "requests/sec (rps)", id: "reqps", fn: simpleCountUnit("req/s") },
      { name: "reads/sec (rps)", id: "rps", fn: simpleCountUnit("rd/s") },
      { name: "writes/sec (wps)", id: "wps", fn: simpleCountUnit("wr/s") },
      { name: "I/O ops/sec (iops)", id: "iops", fn: simpleCountUnit("io/s") },
      { name: "counts/min (cpm)", id: "cpm", fn: simpleCountUnit("c/m") },
      { name: "ops/min (opm)", id: "opm", fn: simpleCountUnit("ops/m") },
      { name: "reads/min (rpm)", id: "rpm", fn: simpleCountUnit("rd/m") },
      { name: "writes/min (wpm)", id: "wpm", fn: simpleCountUnit("wr/m") }
    ]
  },
  {
    name: "Velocity",
    formats: [
      { name: "meters/second (m/s)", id: "velocityms", fn: toFixedUnit("m/s") },
      {
        name: "kilometers/hour (km/h)",
        id: "velocitykmh",
        fn: toFixedUnit("km/h")
      },
      { name: "miles/hour (mph)", id: "velocitymph", fn: toFixedUnit("mph") },
      { name: "knot (kn)", id: "velocityknot", fn: toFixedUnit("kn") }
    ]
  },
  {
    name: "Volume",
    formats: [
      { name: "millilitre (mL)", id: "mlitre", fn: SIPrefix("L", -1) },
      { name: "litre (L)", id: "litre", fn: SIPrefix("L") },
      { name: "cubic meter", id: "m3", fn: toFixedUnit("m\xB3") },
      { name: "Normal cubic meter", id: "Nm3", fn: toFixedUnit("Nm\xB3") },
      { name: "cubic decimeter", id: "dm3", fn: toFixedUnit("dm\xB3") },
      { name: "gallons", id: "gallons", fn: toFixedUnit("gal") }
    ]
  },
  {
    name: "Boolean",
    formats: [
      {
        name: "True / False",
        id: "bool",
        fn: booleanValueFormatter("True", "False")
      },
      {
        name: "Yes / No",
        id: "bool_yes_no",
        fn: booleanValueFormatter("Yes", "No")
      },
      {
        name: "On / Off",
        id: "bool_on_off",
        fn: booleanValueFormatter("On", "Off")
      }
    ]
  }
];

// src/valueFormats/valueFormats.ts
function formattedValueToString(val) {
  return `${val.prefix ?? ""}${val.text}${val.suffix ?? ""}`;
}
var categories = [];
var index = {};
var hasBuiltIndex = false;
function toFixed(value, decimals) {
  if (value === null) {
    return "";
  }
  if (value === Number.NEGATIVE_INFINITY || value === Number.POSITIVE_INFINITY) {
    return value.toLocaleString();
  }
  if (decimals === null || decimals === void 0) {
    decimals = getDecimalsForValue(value);
  }
  const factor = decimals ? Math.pow(10, Math.max(0, decimals)) : 1;
  const formatted = String(Math.round(value * factor) / factor);
  if (formatted.indexOf("e") !== -1 || value === 0) {
    return formatted;
  }
  const decimalPos = formatted.indexOf(".");
  const precision = decimalPos === -1 ? 0 : formatted.length - decimalPos - 1;
  if (precision < decimals) {
    return (precision ? formatted : formatted + ".") + String(factor).substr(1, decimals - precision);
  }
  return formatted;
}
function getDecimalsForValue(value) {
  const log10 = Math.floor(Math.log(Math.abs(value)) / Math.LN10);
  let dec = -log10 + 1;
  const magn = Math.pow(10, -dec);
  const norm = value / magn;
  if (norm > 2.25) {
    ++dec;
  }
  if (value % 1 === 0) {
    dec = 0;
  }
  return Math.max(0, dec);
}
function toFixedScaled(value, decimals, ext) {
  return {
    text: toFixed(value, decimals),
    suffix: ext
  };
}
function toFixedUnit(unit, asPrefix) {
  return (size, decimals) => {
    if (size === null) {
      return { text: "" };
    }
    const text = toFixed(size, decimals);
    if (unit) {
      if (asPrefix) {
        return { text, prefix: unit };
      }
      return { text, suffix: " " + unit };
    }
    return { text };
  };
}
function isBooleanUnit(unit) {
  return unit && unit.startsWith("bool");
}
function booleanValueFormatter(t, f) {
  return (value) => {
    return { text: value ? t : f };
  };
}
function scaledUnits(factor, extArray, sizeFactor = 1) {
  return (size, decimals, scaledDecimals) => {
    if (size === null) {
      return { text: "" };
    }
    if (size === Number.NEGATIVE_INFINITY || size === Number.POSITIVE_INFINITY || isNaN(size)) {
      return { text: size.toLocaleString() };
    }
    let value = size * sizeFactor;
    let steps = 0;
    const limit = extArray.length;
    while (Math.abs(value) >= factor) {
      steps++;
      value /= factor;
      if (steps >= limit) {
        return { text: "NA" };
      }
    }
    return { text: toFixed(value, decimals), suffix: extArray[steps] };
  };
}
function locale(value, decimals) {
  if (value == null) {
    return { text: "" };
  }
  return {
    text: value.toLocaleString(void 0, {
      maximumFractionDigits: decimals
    })
  };
}
function simpleCountUnit(symbol) {
  const units2 = ["", "K", "M", "B", "T"];
  const scaler = scaledUnits(1e3, units2);
  return (size, decimals, scaledDecimals) => {
    if (size === null) {
      return { text: "" };
    }
    const v = scaler(size, decimals, scaledDecimals);
    v.suffix += " " + symbol;
    return v;
  };
}
function stringFormater(value) {
  return { text: `${value}` };
}
function buildFormats() {
  categories = getCategories();
  for (const cat of categories) {
    for (const format of cat.formats) {
      index[format.id] = format.fn;
    }
  }
  [{ from: "farenheit", to: "fahrenheit" }].forEach((alias) => {
    const f = index[alias.to];
    if (f) {
      index[alias.from] = f;
    }
  });
  hasBuiltIndex = true;
}
function getValueFormat(id) {
  if (!id) {
    return toFixedUnit("");
  }
  if (!hasBuiltIndex) {
    buildFormats();
  }
  const fmt = index[id];
  if (!fmt && id) {
    let idx = id.indexOf(":");
    if (idx > 0) {
      const key = id.substring(0, idx);
      const sub = id.substring(idx + 1);
      if (key === "prefix") {
        return toFixedUnit(sub, true);
      }
      if (key === "suffix") {
        return toFixedUnit(sub, false);
      }
      if (key === "time") {
        return toDateTimeValueFormatter(sub);
      }
      if (key === "si") {
        const offset = getOffsetFromSIPrefix(sub.charAt(0));
        const unit = offset === 0 ? sub : sub.substring(1);
        return SIPrefix(unit, offset);
      }
      if (key === "count") {
        return simpleCountUnit(sub);
      }
      if (key === "currency") {
        return currency(sub);
      }
      if (key === "bool") {
        idx = sub.indexOf("/");
        if (idx >= 0) {
          const t = sub.substring(0, idx);
          const f = sub.substring(idx + 1);
          return booleanValueFormatter(t, f);
        }
        return booleanValueFormatter(sub, "-");
      }
    }
    return toFixedUnit(id);
  }
  return fmt;
}
function getValueFormatterIndex() {
  if (!hasBuiltIndex) {
    buildFormats();
  }
  return index;
}
function getValueFormats() {
  if (!hasBuiltIndex) {
    buildFormats();
  }
  return categories.map((cat) => {
    return {
      text: cat.name,
      submenu: cat.formats.map((format) => {
        return {
          text: format.name,
          value: format.id
        };
      })
    };
  });
}
export {
  AlertState,
  DefaultTimeZone,
  ISO_8601,
  InternalTimeZones,
  MappingType,
  SpecialValueMatch,
  SystemDateFormatsState,
  TIME_FORMAT,
  ThresholdsMode,
  addDurationToDate,
  anyToNumber,
  booleanValueFormatter,
  datemath_exports as dateMath,
  dateTime,
  dateTimeAsMoment,
  dateTimeForTimeZone,
  dateTimeFormat,
  dateTimeFormatISO,
  dateTimeFormatTimeAgo,
  dateTimeFormatWithAbbrevation,
  dateTimeParse,
  durationToMilliseconds,
  escapeStringForRegex,
  findHighlightChunksInText,
  findMatchesInText,
  formattedValueToString,
  getDefaultRelativeTimeRange,
  getDefaultTimeRange,
  getLocale,
  getLocaleData,
  getTimeZone,
  getTimeZoneGroups,
  getTimeZoneInfo,
  getTimeZones,
  getValueFormat,
  getValueFormats,
  getValueFormatterIndex,
  getWeekdayIndex,
  intervalToAbbreviatedDurationString,
  isBooleanUnit,
  isDateTime,
  isValidDate,
  isValidDuration,
  isValidGoDuration,
  localTimeFormat,
  locale,
  objRemoveUndefined,
  parseDuration,
  parseFlags,
  rangeutil_exports as rangeUtil,
  scaledUnits,
  setLocale,
  setTimeZoneResolver,
  setWeekStart,
  simpleCountUnit,
  stringFormater,
  stringStartsAsRegEx,
  stringToJsRegex,
  stringToMs,
  systemDateFormats,
  timeZoneAbbrevation,
  timeZoneFormatUserFriendly,
  toDuration,
  toFixed,
  toFixedScaled,
  toFixedUnit,
  toFloatOrUndefined,
  toIntegerOrUndefined,
  toNumberString,
  toOption,
  toPascalCase,
  toUtc,
  unEscapeStringFromRegex
};
