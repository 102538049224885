import React, {
  PropsWithChildren,
  useContext,
  useEffect,
  useState,
} from "react";
import { useAppDispatch, useAppSelector } from "./store";
import {
  fetchDevices,
  onDeviceConfiguration,
  onDeviceStatus,
  subscribeToDeviceStates,
  fetchDeviceEvents,
  subscribeToDeviceEvents,
  onDeviceEventsAdded,
  onDeviceEventsAcknowledged,
  onTenantDeviceEventsAcknowledged,
  onDeviceEventAcknowledged,
} from "../features/devices/devices-slice";
import { fetchTags } from "../features/tags/tags-slice";
import {
  fetchTariffs,
  fetchTariffsRates,
} from "../features/tariff/tariff-slice";
import { fetchTenant, selectTenant } from "../features/tenants/tenants-slice";
import { useUserAbility } from "../auth/user-ability-provider";
import { ZenObservable } from "zen-observable-ts";
import { LoadingIndicatorFullScreen } from "../components/loading/loading-indicator-full-screen";
import { Transition } from "@headlessui/react";
import { selectConnectionState } from "./app-slice";
import { selectCurrentUser } from "../features/tenants/users/users-slice";

/**
 * The data context is responsible to keep subscription data and other live events going
 */
const DataContext = React.createContext<{
  isConnected: boolean;
  isLoading: boolean;
  isFirstLoad: boolean;
}>({
  isConnected: false,
  isLoading: false,
  isFirstLoad: true,
});

export const useDataContext = () => {
  return useContext(DataContext);
};

export function DataContextProvider({ children }: PropsWithChildren<{}>) {
  const dispatch = useAppDispatch();
  const ability = useUserAbility();

  const currentUser = useAppSelector(selectCurrentUser);
  const tenant = useAppSelector(selectTenant);
  const connectionState = useAppSelector(selectConnectionState);

  let deviceStatusSubscription: ZenObservable.Subscription | null = null;
  let deviceEventsSubscription: ZenObservable.Subscription | null = null;

  const [firstLoad, setFirstLoad] = useState<boolean>(true);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const shouldLoadTenant = tenant == null && currentUser != null;

  // Load the tenant
  useEffect(() => {
    if (shouldLoadTenant) {
      const promise = dispatch(fetchTenant());

      return () => {
        promise.abort();
      };
    }
  }, [dispatch, shouldLoadTenant]);

  // Fetch data
  useEffect(() => {
    if (
      tenant !== null &&
      tenant.id !== undefined &&
      connectionState === "Connected"
    ) {
      // TODO combine queries, see https://levelup.gitconnected.com/how-to-run-multiple-queries-at-once-using-graphqls-apollo-client-c24bea52e079

      const fetchPromise = Promise.all([
        dispatch(fetchDevices({})),
        dispatch(fetchTags({})),
        dispatch(fetchTariffs({})),
        dispatch(fetchTariffsRates({})),
      ]);

      if (ability.can("use", "events")) {
        dispatch(fetchDeviceEvents({}));
      }

      fetchPromise.then(() => {
        setIsLoading(false);
        setFirstLoad(false);
      });

      return () => {};
    }
  }, [tenant?.id, dispatch, ability, connectionState]);

  const subscribeToEvents = () => {
    deviceStatusSubscription?.unsubscribe();
    deviceEventsSubscription?.unsubscribe();

    if (tenant !== null && tenant.id !== undefined) {
      deviceStatusSubscription = subscribeToDeviceStates({
        tenant_id: tenant.id,
        onDeviceStatus: (status) => {
          if (status) dispatch(onDeviceStatus(status));
        },
        onDeviceConfiguration: (configuration) => {
          if (configuration) dispatch(onDeviceConfiguration(configuration));
        },
        onError: (error) => {
          console.error(error);
        },
      });

      deviceEventsSubscription = subscribeToDeviceEvents({
        tenant_id: tenant.id,
        onDeviceEventsUpdate: ({
          mutation_type,
          tenant_id,
          device_id,
          events,
        }) => {
          switch (mutation_type) {
            case "addDeviceEvents":
              dispatch(onDeviceEventsAdded(events));
              break;
            case "acknowledgeDeviceEvent":
              dispatch(onDeviceEventAcknowledged(events));
              break;
            case "acknowledgeAllTenantDeviceEvents":
              dispatch(
                onTenantDeviceEventsAcknowledged({
                  tenant_id: tenant_id,
                })
              );
              break;
            case "acknowledgeAllDeviceEvents":
              dispatch(
                onDeviceEventsAcknowledged({
                  device_id: device_id,
                })
              );
          }
        },
        onError: (error) => {
          console.error(error);
        },
      });
    }
  };

  useEffect(() => {
    subscribeToEvents();

    return () => {
      deviceStatusSubscription?.unsubscribe();
      deviceEventsSubscription?.unsubscribe();
    };
  }, [dispatch, tenant?.id, ability]);

  return (
    <DataContext.Provider
      value={{
        isLoading: isLoading,
        isFirstLoad: firstLoad,
        isConnected: false,
      }}
    >
      <LoadingWrapper>{children}</LoadingWrapper>
    </DataContext.Provider>
  );
}

function LoadingWrapper({ children }: PropsWithChildren<{}>) {
  const { isFirstLoad, isLoading } = useDataContext();

  const showLoadingIndicator = isFirstLoad && isLoading;

  return (
    <>
      <Transition
        show={showLoadingIndicator}
        appear={true}
        leave="transition-opacity duration-500"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <LoadingIndicatorFullScreen />
      </Transition>
      {!showLoadingIndicator && children}
    </>
  );
}
